import LanguageSelect from "@/admin/asset/tracks/LanguageSelect"
import {
  AssetTrackFormLanguageSectionFragment$key,
  LanguageCode,
} from "@/admin/asset/tracks/__generated__/AssetTrackFormLanguageSectionFragment.graphql"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { DiscoFormControl, DiscoSelectSkeleton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  form: FormStore<{ languageCode?: LanguageCode | null }, any>
  assetKey: AssetTrackFormLanguageSectionFragment$key
}

function AssetTrackFormLanguageSection({ form, assetKey }: Props) {
  const asset = useFragment<AssetTrackFormLanguageSectionFragment$key>(
    graphql`
      fragment AssetTrackFormLanguageSectionFragment on Asset {
        notAutoGeneratedSubtitleTracks: subtitleTracks(isAutoGenerated: false) {
          edges {
            node {
              id
              languageCode
            }
          }
        }
      }
    `,
    assetKey
  )

  const subtitleTracks = Relay.connectionToArray(asset.notAutoGeneratedSubtitleTracks)
  // Don't let users add more than 1 subtitle track per language
  // (or else there will be multiple subtitle options named the same thing in the video player)
  const excludeValues = form.state.languageCode // When updating, this is the current language code so include it in the list of values
    ? subtitleTracks
        .filter(
          (track) => track.languageCode && track.languageCode !== form.state.languageCode
        )
        .map((track) => track.languageCode)
    : subtitleTracks.filter(Boolean).map((track) => track.languageCode)

  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-md-600"}>{"Subtitle or caption language"}</DiscoText>
      }
      error={Boolean(form.errorsByField.languageCode)}
      errorMessages={form.errorsByField.languageCode}
      marginBottom={2}
    >
      <LanguageSelect
        testid={"AssetTrackFormLanguageSection.languageCodeSelect"}
        placeholder={"What language are these subtitles in?"}
        value={form.state.languageCode}
        onChange={(v) => handleChangeLanguageCode(v as LanguageCode | null)}
        disableClearable
        excludeValues={excludeValues as LanguageCode[]}
      />
    </DiscoFormControl>
  )

  function handleChangeLanguageCode(languageCode: LanguageCode | null) {
    form.state.languageCode = languageCode || undefined
  }
}

export function AssetTrackFormLanguageSectionSkeleton() {
  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-md-600"}>{"Subtitle or caption language"}</DiscoText>
      }
      marginBottom={2}
    >
      <DiscoSelectSkeleton />
    </DiscoFormControl>
  )
}

export default observer(AssetTrackFormLanguageSection)
