/**
 * @generated SignedSource<<0ad53454c47bb8c90f3b52abb3e8b09c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminContentLibraryListTableRowFragment$data = {
  readonly id: string;
  readonly name: string | null;
  readonly type: ContentType;
  readonly label: string;
  readonly usages: {
    readonly totalCount: number;
  };
  readonly organizationUsages?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ContentInstancesTooltipFragment" | "ContentThumbnailWithDetails_ContentFragment" | "ContentAdminDropdown_ContentFragment">;
  readonly " $fragmentType": "AdminContentLibraryListTableRowFragment";
};
export type AdminContentLibraryListTableRowFragment$key = {
  readonly " $data"?: AdminContentLibraryListTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminContentLibraryListTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSurvey"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminContentLibraryListTableRowFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "usages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isSurvey",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "organizationUsages",
          "args": [
            {
              "kind": "Literal",
              "name": "entities",
              "value": [
                "organization"
              ]
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "usages(entities:[\"organization\"],first:1)"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentInstancesTooltipFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentThumbnailWithDetails_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentAdminDropdown_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};
})();

(node as any).hash = "a9e993301975be109c1373389e614d67";

export default node;
