import { AutomationTemplateKind } from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import {
  AUTOMATION_TEMPLATE_COVERS,
  AVAILABLE_STEP_ICONS,
} from "@/admin/automation/utils/automationUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface AutomationTemplateCoverProps extends TestIDProps {
  template: AutomationTemplateKind
}

function AutomationTemplateCover({ template }: AutomationTemplateCoverProps) {
  const classes = useStyles()
  const steps = AUTOMATION_TEMPLATE_COVERS[template]

  if (!steps.length) {
    return (
      <div className={classes.custom}>
        <DiscoIcon icon={"add-circle"} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {steps.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={classes.icons}>
          {AVAILABLE_STEP_ICONS[step]}
          {index < steps.length - 1 && (
            <DiscoIcon icon={"arrow-stem-right"} className={classes.arrow} />
          )}
        </div>
      ))}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    gap: theme.spacing(1),
  },
  custom: {
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.big,
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
  icons: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),

    "& > img": {
      height: "40px",
      width: "40px",
    },
  },
  arrow: {
    flexShrink: 0,
  },
}))
export default AutomationTemplateCover
