import useChatBotDrawer from "@/admin/integrations/chat-bot/useChatBotDrawer"
import { AIChatProvider } from "@/core/context/AIChatContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { observer } from "mobx-react-lite"

const ChatBotDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "chat-bot-drawer-content" */ "@/admin/integrations/chat-bot/ChatBotDrawerContent"
    )
)

function ChatBotDrawer() {
  const classes = useStyles()
  const { isOpen, close } = useChatBotDrawer()

  return (
    <DiscoDrawer
      open={isOpen}
      onClose={handleClose}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
      fullScreen
    >
      <AIChatProvider>
        <ChatBotDrawerContent />
      </AIChatProvider>
    </DiscoDrawer>
  )

  function handleClose() {
    close()
  }
}

const useStyles = makeUseStyles({
  drawerContainer: {
    display: "grid",
    gridTemplateColumns: "294px 1fr",
    padding: 0,
  },
})

export default observer(ChatBotDrawer)
