/**
 * @generated SignedSource<<da684eef1ac96a0392d5bd029594c328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type RoleAvatarStackQuery$variables = {
  id: string;
  organizationRoles?: ReadonlyArray<OrganizationRole> | null;
  skipOrganization: boolean;
  productRoles?: ReadonlyArray<ProductRole> | null;
  skipProduct: boolean;
  first: number;
  after?: string | null;
};
export type RoleAvatarStackQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"RoleAvatarStack_OrganizationRolePaginationFragment" | "RoleAvatarStack_ProductRolePaginationFragment">;
  } | null;
};
export type RoleAvatarStackQuery = {
  variables: RoleAvatarStackQuery$variables;
  response: RoleAvatarStackQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationRoles"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productRoles"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipOrganization"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipProduct"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v9 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v8/*: any*/),
  {
    "kind": "Literal",
    "name": "direction",
    "value": "ASC"
  },
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "member_name"
  },
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "organizationRoles"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v14 = {
  "alias": "first_name",
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": "last_name",
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfoObjectType",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v20 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "productRoles"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleAvatarStackQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "skipOrganization",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "organizationRoles",
                        "variableName": "organizationRoles"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "RoleAvatarStack_OrganizationRolePaginationFragment"
                  }
                ]
              },
              {
                "condition": "skipProduct",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "productRoles",
                        "variableName": "productRoles"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "RoleAvatarStack_ProductRolePaginationFragment"
                  }
                ]
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RoleAvatarStackQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "skipOrganization",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "OrganizationMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "organizationMemberships",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembershipNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationMembership",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "member",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "filters": [
                      "roles",
                      "orderBy",
                      "direction"
                    ],
                    "handle": "connection",
                    "key": "RoleAvatarStack__organizationMemberships",
                    "kind": "LinkedHandle",
                    "name": "organizationMemberships"
                  }
                ]
              },
              {
                "condition": "skipProduct",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "UserNodeConnection",
                    "kind": "LinkedField",
                    "name": "productRoleUsers",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "filters": [
                      "roles"
                    ],
                    "handle": "connection",
                    "key": "RoleAvatarStack__productRoleUsers",
                    "kind": "LinkedHandle",
                    "name": "productRoleUsers"
                  }
                ]
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ab596a2eca6bc2bcf2af473bbd92fdf",
    "id": null,
    "metadata": {},
    "name": "RoleAvatarStackQuery",
    "operationKind": "query",
    "text": "query RoleAvatarStackQuery(\n  $id: ID!\n  $organizationRoles: [OrganizationRole!]\n  $skipOrganization: Boolean!\n  $productRoles: [ProductRole!]\n  $skipProduct: Boolean!\n  $first: Int!\n  $after: String\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      ...RoleAvatarStack_OrganizationRolePaginationFragment_NOIuR @skip(if: $skipOrganization)\n      ...RoleAvatarStack_ProductRolePaginationFragment_496vDX @skip(if: $skipProduct)\n    }\n    id\n  }\n}\n\nfragment RoleAvatarStack_OrganizationRolePaginationFragment_NOIuR on Organization {\n  organizationMemberships(roles: $organizationRoles, first: $first, after: $after, orderBy: member_name, direction: ASC) {\n    totalCount\n    edges {\n      node {\n        id\n        member {\n          id\n          first_name: firstName\n          last_name: lastName\n          avatar\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment RoleAvatarStack_ProductRolePaginationFragment_496vDX on Organization {\n  productRoleUsers(roles: $productRoles, first: $first, after: $after) {\n    totalCount\n    edges {\n      node {\n        id\n        first_name: firstName\n        last_name: lastName\n        avatar\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "43f6b2a464a54b49f819977fd00aef39";

export default node;
