import { useChatBotFragment$key } from "@/admin/integrations/chat-bot/util/__generated__/useChatBotFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function useChatBot() {
  const activeOrganization = useActiveOrganization()!
  const organization = useFragment<useChatBotFragment$key>(
    graphql`
      fragment useChatBotFragment on Organization {
        chatBot {
          id
          name
          status
          personality
          embeddingsIndexName
          embeddingsNamespace
          temperature
          confidence
          emojiUse
          kind
          streamChatUserId
        }
      }
    `,
    activeOrganization
  )

  return organization.chatBot
}

export default useChatBot
