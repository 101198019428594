import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const AssetDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "asset-drawer-content" */ "@/admin/asset/drawer/AssetDrawerContent"
    )
)

export default function AssetDrawer() {
  const drawer = useGlobalDrawer("asset")
  const modal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer open={drawer.isOpen} size={"xl"} onClose={drawer.close} fullScreen>
        {drawer.isOpen && <AssetDrawerContent />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )
}
