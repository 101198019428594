/**
 * @generated SignedSource<<8a95c4a06972cd7d3b6657757f0c7d3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditOrganizationRegistrationSettingsInput = {
  registrationDescription?: string | null;
  organizationId: string;
  questions: ReadonlyArray<QuestionInput>;
};
export type QuestionInput = {
  id: string;
  richEditorBody?: string | null;
  type: string;
  isRequired: boolean;
};
export type AdminRegistrationSettingsFormMutation$variables = {
  input: EditOrganizationRegistrationSettingsInput;
};
export type AdminRegistrationSettingsFormMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly registrationDescription: string | null;
      readonly questions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly richEditorBody: string | null;
            readonly type: string;
            readonly isRequired: boolean;
          };
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AdminRegistrationSettingsFormMutation = {
  variables: AdminRegistrationSettingsFormMutation$variables;
  response: AdminRegistrationSettingsFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditOrganizationRegistrationSettingsResponse",
    "kind": "LinkedField",
    "name": "editOrganizationRegistrationSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QuestionNodeConnection",
            "kind": "LinkedField",
            "name": "questions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Question",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "richEditorBody",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isRequired",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminRegistrationSettingsFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminRegistrationSettingsFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "08c0d9bd8e2606ec10240692464079cc",
    "id": null,
    "metadata": {},
    "name": "AdminRegistrationSettingsFormMutation",
    "operationKind": "mutation",
    "text": "mutation AdminRegistrationSettingsFormMutation(\n  $input: EditOrganizationRegistrationSettingsInput!\n) {\n  response: editOrganizationRegistrationSettings(input: $input) {\n    node {\n      id\n      registrationDescription\n      questions {\n        edges {\n          node {\n            id\n            richEditorBody\n            type\n            isRequired\n          }\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0287f7da60ab4e3e92a03921fbf87ff8";

export default node;
