import SlackUserImportPageHeader from "@/admin/integrations/slack/setup/SlackUserImportPageHeader"
import { SlackUserImportNeedsMappingStepContentMutation } from "@/admin/integrations/slack/setup/sub-pages/__generated__/SlackUserImportNeedsMappingStepContentMutation.graphql"
import { useActiveSlackUserImportContext } from "@/core/context/ActiveSlackUserImportContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import UnmappedSlackUserReportTable from "@/slack/slack-user-import-report/UnmappedSlackUserReportTable"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoSection } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql } from "relay-runtime"

type Props = {
  importId: GlobalID
}

function SlackUserImportNeedsMappingStepContent(props: Props) {
  const { importId } = props
  const { pendingMatches } = useActiveSlackUserImportContext()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const classes = useStyles()

  const form = useFormStore<SlackUserImportNeedsMappingStepContentMutation>(
    graphql`
      mutation SlackUserImportNeedsMappingStepContentMutation(
        $input: UpdateSlackUserImportInput!
      ) {
        response: updateSlackUserImport(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: importId,
      status: "inprogress",
    }
  )
  const hasPending = Boolean(pendingMatches)

  return (
    <>
      <SlackUserImportPageHeader
        title={"Manual Account Mapping"}
        subtitle={
          "You're nearly there! However, we found a few accounts in your Disco community that we can't match to members in your Slack workspace. Members may have used a different email address or they may not yet be in your Slack workspace. Manually match members if possible and confirm your selection."
        }
      />
      <DiscoSection className={classes.table} padding={0}>
        <UnmappedSlackUserReportTable />
        <div className={classes.tableButtonContainer}>
          <DiscoButton
            onClick={() =>
              hasPending ? setIsConfirmationModalOpen(true) : handleSubmit()
            }
            disabled={form.isSubmitting}
          >
            {hasPending ? "Confirm Selections" : "Continue"}
          </DiscoButton>
        </div>
      </DiscoSection>
      <DiscoWarningModal
        testid={"SlackManualMappingConfirmationModal"}
        variant={"warning"}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={"Confirm Account Connections"}
        customConfirmationButton={
          <DiscoButton
            width={"100%"}
            onClick={handleSubmit}
            shouldDisplaySpinner={form.isSubmitting}
          >
            {"Yes, Map Accounts"}
          </DiscoButton>
        }
        cancelButtonText={"Cancel"}
        modalContentLabel={"Delete Automation Confirmation Modal"}
        description={
          "Confirm you would like to map the selected Disco/Slack accounts. There is no way to undo this action."
        }
      />
    </>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)

    if (!didSave) return

    displaySuccessToast({
      message: "Slack account mapping confirmed!",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  table: {
    maxWidth: "745px",
    width: "100%",
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  tableButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    margin: theme.spacing(0, 2.5, 2.5),
  },
}))

export default Relay.withSkeleton({
  skeleton: () => null,
  component: SlackUserImportNeedsMappingStepContent,
})
