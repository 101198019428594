import AutomationTemplatesGrid from "@/admin/automation/templates/AutomationTemplatesGrid"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal } from "@disco-ui"
import { observer } from "mobx-react-lite"

export interface CreateAutomationModalProps {
  onClose: () => void
}

function CreateAutomationModal(props: CreateAutomationModalProps) {
  const { onClose } = props
  const classes = useStyles()

  return (
    <DiscoModal
      isOpen
      onClose={onClose}
      title={"New Automation"}
      subtitle={"Add a new automation to create a workflow for your learning community."}
      modalContentLabel={"New Automation"}
      testid={"CreateAutomationModal.template-picker"}
      body={<AutomationTemplatesGrid onClose={onClose} />}
      classes={{
        container: classes.modalWidth,
      }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  modalWidth: {
    width: theme.measure.modalMaxWidth.large,
    [theme.breakpoints.only("sm")]: {
      width: theme.measure.modalMaxWidth.medium,
    },
    [theme.breakpoints.only("xs")]: {
      width: theme.measure.modalMaxWidth.mobile,
    },
  },
}))

export default observer(CreateAutomationModal)
