import { BadgeInput } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import AppIcon from "@/apps/list/app/AppIcon"
import { AppIconKind } from "@/apps/util/activeAppModalContext"
import { ObservableState } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ColorUtils from "@assets/style/util/ColorUtils"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton, DiscoText } from "@disco-ui"
import DiscoBadgePicker from "@disco-ui/badge-picker/DiscoBadgePicker"
import { useTheme } from "@material-ui/core"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface CreateBadgeModalProps extends TestIDProps {
  badge: ObservableState<BadgeInput>
  name?: string
  variant?: "default" | "onboarding" | "settings"
}

function CreateBadgeModalButton({
  name = "",
  badge,
  variant = "default",
  testid = "CreateBadgeModalButton",
}: CreateBadgeModalProps) {
  const theme = useTheme()
  const badgeColor = ColorUtils.makeSafeHex(badge?.color)
  const svgColor = ColorUtils.getContrastColor(theme, badgeColor)
  const classes = useStyles({ badgeColor, svgColor, variant })
  const acronymColor = ColorUtils.getContrastColor(
    theme,
    badge?.color || theme.palette.common.white
  )
  const showAcronym = badge?.kind === "icon" && !badge?.icon
  const iconSize = variant === "settings" ? 56 : variant === "onboarding" ? 40 : 24

  return (
    <DiscoBadgePicker badge={badge} testid={`${testid}.DiscoBadgePicker`}>
      {(props) => (
        <div
          data-testid={`${testid}.badge-container`}
          className={classes.container}
          {...props}
        >
          <div className={classes.previewBadge}>{getBadge()}</div>
          {showAcronym && (
            <DiscoText className={classes.acronym} setColor={acronymColor}>
              {getInitialLettersOfWords(name).slice(0, 2)}
            </DiscoText>
          )}
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            leftIcon={"pencil"}
            width={"40px"}
            className={classes.changeButton}
            style={{ cursor: "pointer" }}
            customButtonColor={{
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.primary.contrastText,
              hover: {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.contrastText,
              },
              border: theme.palette.groovy.neutral[200],
            }}
          />
        </div>
      )}
    </DiscoBadgePicker>
  )

  function getBadge() {
    if (badge.kind === "upload")
      return badge!.mediaUrl ? (
        <img src={badge!.mediaUrl!} alt={""} className={classes.previewBadge} />
      ) : (
        <div>{""}</div>
      )

    if (badge.kind === "emoji")
      return <DiscoText className={classes.emoji}>{badge!.emoji}</DiscoText>

    return (
      <AppIcon height={iconSize} width={iconSize} kind={badge.icon! as AppIconKind} />
    )
  }
}

type StyleProps = {
  svgColor: string | undefined
  badgeColor: string | undefined
  variant: "default" | "onboarding" | "settings"
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ variant }: StyleProps) => ({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...styleIf(variant === "settings", {
      width: "104px",
      height: "104px",
    }),
    ...styleIf(variant === "onboarding", {
      width: "72px",
      height: "72px",
    }),
    ...styleIf(variant !== "settings" && variant !== "onboarding", {
      width: "36px",
      height: "40px",
      borderRight: `1px solid ${theme.palette.constants.divider}`,
      marginRight: theme.spacing(1.5),
      marginLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    }),

    "&:hover $previewBadge": {
      opacity: variant === "settings" ? 0.5 : 1,
    },
    "&:hover $changeButton": {
      display: variant === "settings" ? "flex" : "none", // Show button only in settings variant
    },
  }),
  previewBadge: ({ svgColor, badgeColor, variant }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    justifyContent: "center",
    backgroundColor: badgeColor || theme.palette.primary.main,
    ...styleIf(variant === "settings", {
      width: "104px",
      height: "104px",
      border: "1px solid #E4E4E7",
      borderRadius: theme.measure.borderRadius.large,
    }),
    ...styleIf(variant === "onboarding", {
      width: "72px",
      height: "72px",
      border: "1px solid #E4E4E7",
      borderRadius: theme.measure.borderRadius.large,
    }),
    ...styleIf(variant !== "settings" && variant !== "onboarding", {
      width: "24px",
      height: "24px",
      border: "24px",
      borderRadius: theme.measure.borderRadius.default,
    }),
    "& svg": {
      color: svgColor,
    },
  }),
  changeButton: {
    display: "none",
    position: "absolute",
  },
  acronym: {
    position: "absolute",
    fontSize: "36px",
  },
  emoji: ({ variant }: StyleProps) => ({
    fontSize: "20px",
    ...styleIf(variant === "onboarding", {
      fontSize: "32px",
    }),
    ...styleIf(variant === "settings", {
      fontSize: "40px",
    }),
  }),
}))

export default observer(CreateBadgeModalButton)
