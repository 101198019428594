import { CommunityDirectMessagesPageContentPaginationQuery } from "@/admin/community/direct-messages/__generated__/CommunityDirectMessagesPageContentPaginationQuery.graphql"
import { CommunityDirectMessagesPageContentQuery } from "@/admin/community/direct-messages/__generated__/CommunityDirectMessagesPageContentQuery.graphql"
import {
  CommunityDirectMessagesPageContent_PaginationFragment$data,
  CommunityDirectMessagesPageContent_PaginationFragment$key,
} from "@/admin/community/direct-messages/__generated__/CommunityDirectMessagesPageContent_PaginationFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useStreamChannels } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import DirectMessagesList, {
  DirectMessagesListSkeleton,
} from "@components/chat/channel/list/DirectMessagesList"
import { useEffect, useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { Redirect, generatePath, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { Channel } from "stream-chat"
import { useChatContext } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

export type DirectMessagesListChatChannel = NodeFromConnection<
  CommunityDirectMessagesPageContent_PaginationFragment$data["chatChannels"]
>

const DMS_PER_PAGE = 10

function CommunityDirectMessagesPageContent() {
  const { client: streamClient } = useChatContext()
  const activeOrganization = useActiveOrganization()!

  // Get the channels from our database
  const { organization } = useLazyLoadQuery<CommunityDirectMessagesPageContentQuery>(
    graphql`
      query CommunityDirectMessagesPageContentQuery(
        $id: ID!
        $first: Int!
        $after: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            id
            streamChatTeamId
            ...CommunityDirectMessagesPageContent_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    { id: activeOrganization.id, first: DMS_PER_PAGE },
    { fetchPolicy: "store-and-network" }
  )

  const { data, refetch, loadNext, isLoadingNext, hasNext } = usePaginationFragment<
    CommunityDirectMessagesPageContentPaginationQuery,
    CommunityDirectMessagesPageContent_PaginationFragment$key
  >(
    graphql`
      fragment CommunityDirectMessagesPageContent_PaginationFragment on Organization
      @refetchable(queryName: "CommunityDirectMessagesPageContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        chatChannels(
          kind: direct_message
          orderBy: { field: "last_message_sent_at", direction: DESC }
          first: $first
          after: $after
        ) @connection(key: "CommunityDirectMessagesPageContent__chatChannels") {
          edges {
            node {
              id
              externalChannelId
              ...DirectMessagesListItemFragment
              chatChannelMembers(excludeViewer: true) {
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const chatChannels = Relay.connectionToArray(data?.chatChannels)
    // Filter channels with only one member (viewer is excluded from count)
    .filter((cc) => cc.chatChannelMembers.totalCount)

  // Get the stream channels and filter out those without any messages sent
  const streamChannels = useStreamChannels(
    chatChannels.map((cc) => cc.externalChannelId),
    true
  )
  const streamChannelsById = streamChannels.reduce((map, sc) => {
    if (sc.id && sc.lastMessage()) map[sc.id] = sc
    return map
  }, {} as Record<string, Channel<DefaultStreamChatGenerics>>)
  const channelsWithMessages = chatChannels.filter(
    (cc) => streamChannelsById[cc.externalChannelId]
  )
  const areStreamChannelsLoading = Boolean(chatChannels.length && !streamChannels.length)

  const [_, setRerender] = useState(false)
  useEffect(() => {
    if (!streamClient) return
    const { unsubscribe } = streamClient.on((event) => {
      if (
        event.type === "notification.added_to_channel" &&
        event.channel_id &&
        !streamChannelsById[event.channel_id]
      ) {
        // Refresh channels list if added to any new channel, since we can't know if it's
        // a DM channel from the Stream event
        refetch(
          { id: activeOrganization.id, first: DMS_PER_PAGE },
          { fetchPolicy: "store-and-network" }
        )
      } else if (
        event.type === "message.new" &&
        event.channel_id &&
        // If it's for one of our DM channels
        streamChannels.some((sc) => sc.id === event.channel_id) &&
        // And no there's no previous message for this channel
        !streamChannelsById[event.channel_id]
      ) {
        // Force re-render on receiving first message for a channel so it shows in the list
        setRerender((v) => !v)
      }
    })
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamClient, refetch, activeOrganization.id, streamChannels])

  // Redirect to the first DM in the list if none is currenly selected, or to the empty
  // state page if there are no DM channels with messages yet.
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    if (location.pathname !== ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT) return
    // Wait until stream channels load to so we can redirect to a channel with messages
    if (areStreamChannelsLoading) return

    // Only redirect to a DM channel with a message sent
    if (channelsWithMessages.length) {
      history.replace(
        generatePath(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL, {
          channelId: channelsWithMessages[0].id,
        })
      )
    } else {
      history.replace(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_EMPTY)
    }
  }, [channelsWithMessages, location.pathname, history, areStreamChannelsLoading])

  if (!activeOrganization.isDmEnabled) {
    return <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
  }

  // Show skeleton while channels load so the scroll pagination doesn't immediately trigger
  if (areStreamChannelsLoading) return <DirectMessagesListSkeleton variant={"page"} />

  return (
    <DirectMessagesList
      variant={"page"}
      chatChannels={channelsWithMessages}
      isLoading={isLoadingNext}
      hasMore={hasNext}
      loadMore={() => loadNext(DMS_PER_PAGE)}
    />
  )
}

export default Relay.withSkeleton({
  component: CommunityDirectMessagesPageContent,
  skeleton: () => <DirectMessagesListSkeleton variant={"page"} />,
})
