import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface SlackUserImportPageHeaderProps extends TestIDProps {
  title: React.ReactNode
  subtitle: React.ReactNode
}

function SlackUserImportPageHeader({
  title,
  subtitle,
  testid = "SlackUserImportPageHeader",
}: SlackUserImportPageHeaderProps) {
  return (
    <div style={{ maxWidth: "650px" }}>
      <DiscoText variant={"heading-md"} marginBottom={1.25} testid={`${testid}.title`}>
        {title}
      </DiscoText>
      <DiscoText variant={"body-sm"} marginBottom={4} testid={`${testid}.subtitle`}>
        {subtitle}
      </DiscoText>
    </div>
  )
}

export default SlackUserImportPageHeader
