import { SlackContentInput } from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import { AutomationSlackSendAsFieldsQuery } from "@/admin/automation/form/actions/__generated__/AutomationSlackSendAsFieldsQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ObservableState } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import discoBadge from "@/core/ui/images/automations/disco-badge.png"
import { SendSlackDirectMessageInput } from "@/organization/people/actions/message/__generated__/MessageCommunityMembersDrawerContentMutation.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import { getUserFullName } from "@/user/util/userUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoInputSkeleton,
  DiscoLink,
  DiscoSelect,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type Props = TestIDProps & {
  slackContent: ObservableState<SlackContentInput | SendSlackDirectMessageInput>
}

function AutomationSlackSendAsFields(props: Props) {
  const { slackContent } = props

  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!

  // Get the available slack admins
  const { organization } = useLazyLoadQuery<AutomationSlackSendAsFieldsQuery>(
    graphql`
      query AutomationSlackSendAsFieldsQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            viewerMembership {
              id
              user: member {
                id
                first_name: firstName
                last_name: lastName
                avatar
                ...ProfileAvatarWithDetailsFragment
                ...ProfileAvatarFragment
              }
            }
            omscs: organizationMembershipSlackConnections(status: active) {
              edges {
                node {
                  id
                  status
                  organizationMembership {
                    id
                    user: member {
                      id
                      first_name: firstName
                      last_name: lastName
                      avatar
                      ...ProfileAvatarWithDetailsFragment
                      ...ProfileAvatarFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    { fetchPolicy: "network-only" }
  )

  const omscs = Relay.connectionToArray(organization?.omscs)
  const viewerSlackConnection = omscs.find(
    (omsc) => omsc.organizationMembership.id === organization?.viewerMembership?.id
  )

  const connections = [
    // Disco bot
    { id: "bot", organizationMembership: null, title: "Disco Bot", disabled: false },

    // Slack connections
    ...omscs.map((omsc) => ({
      id: omsc.id,
      organizationMembership: omsc.organizationMembership,
      title: getUserFullName(omsc.organizationMembership.user!),
      // Disable if not the viewer
      disabled: omsc.organizationMembership.id !== organization?.viewerMembership?.id,
    })),

    // Viewer if not in connected users
    ...(viewerSlackConnection
      ? []
      : [
          {
            id: "unconnected",
            organizationMembership: organization?.viewerMembership,
            title: getUserFullName(organization!.viewerMembership!.user!),
            disabled: false,
          },
        ]),
  ]

  return (
    <div>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={0.5}>
        {"Send as"}
      </DiscoText>

      <div className={classes.select}>
        <DiscoSelect
          placeholder={"Send as"}
          disableClearable
          value={slackContent.sendAs || "bot"}
          onChange={(sendAs) => handleSelectSendAs(sendAs)}
          options={connections.map((c) => ({
            value: c.id,
            title: c.title,
            disabled: c.disabled,
          }))}
          textFieldInputProps={{
            startAdornment: getStartAdornment(),
          }}
          renderOption={(option) => {
            const user = connections.find((c) => c?.id === option.value)
              ?.organizationMembership?.user

            if (user) {
              return (
                <div className={classes.option}>
                  <ProfileAvatarWithDetails
                    userKey={user}
                    testid={"AutomationSlackSendAsFields"}
                    linkToProfile={false}
                  />
                </div>
              )
            }

            return (
              <div className={classNames(classes.option, classes.profile)}>
                <DiscoImage className={classes.discoLogo} src={discoBadge} />
                <DiscoText variant={"body-sm-600"} marginLeft={1}>
                  {"Disco Bot"}
                </DiscoText>
              </div>
            )
          }}
        />
      </div>

      {/* If the selected user does not have a slack connection */}
      {mustConnectSlack() && (
        <DiscoAlert
          marginBottom={1.5}
          message={
            <DiscoText variant={"body-sm"} component={"span"}>
              {`You must connect your Slack account to send messages as ${sendAsUserFullName()}. Connect your Slack account in`}
              <DiscoLink to={ROUTE_NAMES.ADMIN.INTEGRATIONS.LIST} target={"_blank"}>
                <DiscoText variant={"body-sm"} component={"span"}>
                  {" Settings > Integrations > Slack"}
                </DiscoText>
              </DiscoLink>
            </DiscoText>
          }
        />
      )}
    </div>
  )

  function handleSelectSendAs(sendAs: string | null) {
    slackContent.sendAs = sendAs
  }

  function mustConnectSlack() {
    return slackContent.sendAs === "unconnected"
  }

  function getStartAdornment() {
    const user = connections.find((c) => c?.id === slackContent.sendAs)
      ?.organizationMembership?.user

    if (user) {
      return (
        <div className={classes.adornment}>
          <ProfileAvatar userKey={user} />
        </div>
      )
    }

    return (
      <DiscoImage
        className={classNames(classes.discoLogo, classes.adornment)}
        src={discoBadge}
      />
    )
  }

  function sendAsUserFullName() {
    const user = connections.find((c) => c?.id === slackContent.sendAs)
      ?.organizationMembership?.user
    if (user) getUserFullName(user)
    return "Disco Bot"
  }
}

const useStyles = makeUseStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  select: {
    marginBottom: theme.spacing(1.5),
  },
  adornment: {
    width: `${24}px !important`,
    height: `${24}px !important`,
    marginRight: theme.spacing(1),
  },
  discoLogo: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    border: `2px solid ${theme.palette.common.white}`,
  },
  profile: {
    display: "grid",
    gridTemplateColumns: `32px 1fr`,
    gap: theme.spacing(1.5),
    alignItems: "center",
    minWidth: "min-content",
  },
}))

export function AutomationSlackSendAsFieldsSkeleton() {
  const classes = useStyles()
  return (
    <>
      <DiscoTextSkeleton width={"60px"} />
      <div className={classes.select}>
        <DiscoInputSkeleton />
      </div>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(AutomationSlackSendAsFields),
  skeleton: AutomationSlackSendAsFieldsSkeleton,
})
