/* eslint-disable local-rules/disco-unused-classes */

import SlackUserImportFailureStepContent from "@/admin/integrations/slack/setup/sub-pages/SlackUserImportFailureStepContent"
import SlackUserImportNeedsMappingStepContent from "@/admin/integrations/slack/setup/sub-pages/SlackUserImportNeedsMappingStepContent"
import SlackUserInProgressStepContent from "@/admin/integrations/slack/setup/sub-pages/SlackUserImportProcessingStepContent"
import SlackUserImportSuccessStepContent from "@/admin/integrations/slack/setup/sub-pages/SlackUserImportSuccessStepContent"
import AuthenticationPage from "@/authentication/common/page/AuthenticationPage"
import { useActiveSlackUserImportContext } from "@/core/context/ActiveSlackUserImportContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { SlackUserImportStatus } from "@/core/context/__generated__/ActiveSlackUserImportContextRefetchableQuery.graphql"
import ThreeDotLoader from "@assets/images/onboarding/processing/three-dot-loader.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"

import DiscoSimpleStepper from "@disco-ui/stepper/DiscoSimpleStepper"
import { userIsNew } from "@utils/onboarding/onboardingUtils"
import { useEffect } from "react"

type Props = {
  isOnboarding?: boolean
}

export default function SlackUserImportPage({ isOnboarding }: Props) {
  const { activeImport, startPoll } = useActiveSlackUserImportContext()

  const { authUser: user } = useAuthUser()
  const isNewUser = userIsNew(user?.firstName, user?.lastName)

  const classes = useStyles()

  useEffect(() => {
    startPoll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthenticationPage
      hideBackground
      metaTitle={"Finalize Slack Connection"}
      classes={{
        section: classes.section,
        content: classes.content,
        pageContainer: classes.pageContainer,
      }}
    >
      <div className={classes.root}>
        <div className={classes.stepperContainer}>
          <DiscoSimpleStepper
            steps={isOnboarding ? (isNewUser ? [4, 4] : [3, 3]) : [3, 3]}
          />
        </div>
        <div className={classes.body}>{getStepContent(activeImport?.status)}</div>
      </div>
    </AuthenticationPage>
  )

  function getStepContent(importStatus?: SlackUserImportStatus) {
    if (!importStatus)
      return <SlackUserImportFailureStepContent isOnboarding={isOnboarding} />
    switch (importStatus) {
      case "needsmapping":
        return <SlackUserImportNeedsMappingStepContent importId={activeImport!.id} />
      case "completed":
        return <SlackUserImportSuccessStepContent isOnboarding={isOnboarding} />
      case "failed":
      case "cancelled":
        return <SlackUserImportFailureStepContent isOnboarding={isOnboarding} />
      case "initial":
      default:
        return <SlackUserInProgressStepContent />
    }
  }
}

export function SlackUserImportPageSkeleton() {
  const classes = useStyles()

  return (
    <AuthenticationPage
      hideBackground
      metaTitle={"Finalize Slack Connection"}
      classes={{
        section: classes.section,
        content: classes.content,
        pageContainer: classes.pageContainer,
      }}
    >
      <div style={{ display: "grid", height: "100%", placeContent: "center" }}>
        <ThreeDotLoader />
      </div>
    </AuthenticationPage>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
  body: {
    minHeight: 400,
    maxWidth: 1280,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: 500,
    },
  },

  section: {
    maxWidth: "unset",
    maxHeight: "unset",
    height: "100%",
    margin: 0,
    overflow: "auto",
  },
  content: {
    maxWidth: "unset",
    height: "100%",
  },
  pageContainer: {
    margin: theme.spacing(3),
    height: `calc(100% - ${theme.spacing(6)}px)`,
  },

  stepperContainer: {
    marginTop: "4vh",
  },
}))
