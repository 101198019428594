/**
 * @generated SignedSource<<7c05399d058b22609e573551b052c8b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AutomationBranchFilterOperator = "AND" | "OR" | "%future added value";
export type AutomationFilterLevel = "trigger" | "step" | "branch" | "%future added value";
export type AutomationStepActionKind = "send_email" | "send_slack_direct_message" | "add_to_group" | "remove_from_group" | "add_to_slack_channel" | "remove_from_slack_channel" | "add_to_product" | "remove_from_product" | "send_chat_channel_message" | "send_chat_direct_message" | "release_certificate" | "%future added value";
export type AutomationStepKind = "action" | "delay" | "branch" | "%future added value";
export type AutomationStepWarningKind = "slack_channel_removed" | "%future added value";
export type MessageTemplateKind = "course_welcome" | "community_welcome" | "product_completion" | "custom" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type automationUtils_UpdateAutomationStepBranchesFragment$data = {
  readonly branches: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly color: string;
        readonly filterOperator: AutomationBranchFilterOperator;
        readonly filters: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly level: AutomationFilterLevel;
              readonly condition: string;
            };
          }>;
        };
        readonly steps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly kind: AutomationStepKind;
              readonly actionKind: AutomationStepActionKind | null;
              readonly delay: string | null;
              readonly actionMemberGroupIds: ReadonlyArray<string> | null;
              readonly actionChannels: ReadonlyArray<string> | null;
              readonly actionProductIds: ReadonlyArray<string> | null;
              readonly warningKind: AutomationStepWarningKind | null;
              readonly filters: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                    readonly level: AutomationFilterLevel;
                    readonly condition: string;
                  };
                }>;
              } | null;
              readonly actionMessageTemplate: {
                readonly id: string;
                readonly name: string;
                readonly kind: MessageTemplateKind | null;
                readonly emailContent: {
                  readonly send: boolean;
                  readonly subject: string;
                  readonly richBody: string;
                  readonly ccEmails: ReadonlyArray<string> | null;
                  readonly bccEmails: ReadonlyArray<string> | null;
                  readonly ccUserIds: ReadonlyArray<string> | null;
                  readonly bccUserIds: ReadonlyArray<string> | null;
                } | null;
                readonly slackContent: {
                  readonly send: boolean;
                  readonly sendAs: string | null;
                  readonly richBody: string;
                } | null;
                readonly chatContent: {
                  readonly send: boolean;
                  readonly sendAs: string | null;
                  readonly richBody: string;
                } | null;
              } | null;
            };
          }>;
        };
      };
    }>;
  };
  readonly " $fragmentType": "automationUtils_UpdateAutomationStepBranchesFragment";
};
export type automationUtils_UpdateAutomationStepBranchesFragment$key = {
  readonly " $data"?: automationUtils_UpdateAutomationStepBranchesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"automationUtils_UpdateAutomationStepBranchesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AutomationFilterNodeConnection",
  "kind": "LinkedField",
  "name": "filters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationFilterNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationFilter",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "condition",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richBody",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendAs",
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "automationUtils_UpdateAutomationStepBranchesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationBranchNodeConnection",
      "kind": "LinkedField",
      "name": "branches",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationBranchNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomationBranch",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "filterOperator",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutomationStepNodeConnection",
                  "kind": "LinkedField",
                  "name": "steps",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutomationStepNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AutomationStep",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "actionKind",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "delay",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "actionMemberGroupIds",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "actionChannels",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "actionProductIds",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "warningKind",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MessageTemplate",
                              "kind": "LinkedField",
                              "name": "actionMessageTemplate",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MessageTemplateEmailContent",
                                  "kind": "LinkedField",
                                  "name": "emailContent",
                                  "plural": false,
                                  "selections": [
                                    (v4/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "subject",
                                      "storageKey": null
                                    },
                                    (v5/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "ccEmails",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "bccEmails",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "ccUserIds",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "bccUserIds",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MessageTemplateSlackContent",
                                  "kind": "LinkedField",
                                  "name": "slackContent",
                                  "plural": false,
                                  "selections": (v6/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MessageTemplateChatContent",
                                  "kind": "LinkedField",
                                  "name": "chatContent",
                                  "plural": false,
                                  "selections": (v6/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutomationStep",
  "abstractKey": null
};
})();

(node as any).hash = "751e22ba524ef239efe17e610722571a";

export default node;
