import { LanguageCode } from "@/admin/asset/tracks/__generated__/AssetTracksListItemFragment.graphql"
import Language from "@components/language/languageUtils"
import { DiscoSelect, DiscoSelectProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props
  extends TestIDProps,
    Pick<
      DiscoSelectProps<string, string>,
      "value" | "onChange" | "placeholder" | "disableClearable" | "disabled"
    > {
  excludeValues?: LanguageCode[] | null
}

function LanguageSelect({
  testid,
  value,
  onChange,
  placeholder,
  disableClearable,
  excludeValues,
  disabled,
}: Props) {
  const languageCodes = excludeValues
    ? Language.CODES.filter((code) => !excludeValues.includes(code))
    : Language.CODES

  return (
    <DiscoSelect
      testid={testid}
      placeholder={placeholder}
      options={languageCodes.map((languageCode) => ({
        value: languageCode,
        title: Language.getNameByCode(languageCode),
      }))}
      value={value}
      onChange={onChange}
      disableClearable={disableClearable}
      filterOptions={{ maxVisible: null }}
      disabled={disabled}
    />
  )
}

export default LanguageSelect
