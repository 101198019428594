/**
 * @generated SignedSource<<2527b7a2bcf834cd4f3c3846fa23c8d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentInstancesTooltipFragment$data = {
  readonly usages: {
    readonly totalCount: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AdminContentLibraryItemUsages_ContentFragment">;
  readonly " $fragmentType": "ContentInstancesTooltipFragment";
};
export type ContentInstancesTooltipFragment$key = {
  readonly " $data"?: ContentInstancesTooltipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentInstancesTooltipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentInstancesTooltipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "usages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminContentLibraryItemUsages_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "c9a2926035940cafbbd937dfc4fe54f0";

export default node;
