import useCopilotBot from "@/admin/integrations/copilot/util/useCopilotBot"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import QASuggestionIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const CopilotBotSettingsForm = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "copilot-bot-settings-form" */ "@/admin/integrations/copilot/CopilotBotSettingsForm"
    )
)

function CopilotBotSettingsDrawer() {
  const classes = useStyles()
  const drawer = useGlobalDrawer("copilotSettings")
  const theme = useTheme()
  const modal = useInitUnsavedChangesModalContext()
  const copilotBot = useCopilotBot()
  const copilotBotName = copilotBot?.name || "Q&A Suggestions"

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer open={drawer.isOpen} onClose={handleClose}>
        <DiscoDrawerHeader
          overlineTitle={"Configure"}
          title={copilotBotName}
          onClose={handleClose}
          className={classes.drawerHeader}
        />
        <DiscoAlert
          icon={<QASuggestionIcon />}
          classes={{
            root: classes.alertRoot,
            icon: classes.alertIcon,
            message: classes.alertMessage,
            action: classes.alertAction,
          }}
          title={"Disco AI Q&A"}
          message={`Streamline member interactions with ${copilotBotName}! Disco Al scans selected Disco and Slack channels, like #questions for member questions. Drawing from past responses, ${copilotBotName} creates answers and suggests them in threads (within Disco) or designated channels (within Slack), ready for you to copy, review, and send.`}
          dismissibleKey={"copilot-welcome-alert"}
          gradientBackground={theme.palette.gradient.copilot}
          marginBottom={3}
          paddingTop={1}
          paddingBottom={1}
          learnMoreLink={
            "https://support.disco.co/hc/en-us/articles/25812993784468-Answering-Questions-with-Disco-AI-Suggestions"
          }
        />

        {drawer.isOpen && <CopilotBotSettingsForm />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleClose() {
    drawer.close()
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(3, 3, 0),
  },
  alertRoot: {
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: Number(theme.measure.borderRadius.big) - 1,
    position: "relative",

    // Add a gradient border
    "&:after": {
      content: "''",
      position: "absolute",
      top: "-2px",
      left: "-2px",
      width: "calc(100% + 4px)",
      height: "calc(100% + 4px)",
      background: theme.palette.gradient.copilotDark,
      borderRadius: theme.measure.borderRadius.big,
      zIndex: -theme.zIndex.raise1,
    },
  },
  alertIcon: {
    width: 135,
    height: 135,
    "& svg": {
      width: 135,
      height: 135,
    },
  },
  alertMessage: {
    color: theme.palette.common.black,
  },
  alertAction: {
    color: theme.palette.common.black,
  },
}))

export default CopilotBotSettingsDrawer
