import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import AppIcon from "@/apps/list/app/AppIcon"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import { useLabel } from "@/core/context/LabelsContext"
import spaceCover from "@/core/ui/images/experience-templates/alumni-space.png"
import cohortBasedCourseCover from "@/core/ui/images/experience-templates/cohort-based-course.png"
import AlumniSpaceIcon from "@/core/ui/images/experience-templates/icons/alumni-space.svg"
import BlankIcon from "@/core/ui/images/experience-templates/icons/blank.svg"
import CohortBasedCourseIcon from "@/core/ui/images/experience-templates/icons/cohort-based-course.svg"
import SelfPacedCourseIcon from "@/core/ui/images/experience-templates/icons/self-paced-course.svg"
import SeriesIcon from "@/core/ui/images/experience-templates/icons/series.svg"
import selfPacedCourseCover from "@/core/ui/images/experience-templates/self-paced-course.png"
import seriesCover from "@/core/ui/images/experience-templates/series.png"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { addWeeks, format, startOfWeek } from "date-fns"

interface Props {
  testid: string
  kind: ExperienceTemplateKind
}

function ExperienceTemplateOverview(props: Props) {
  const { testid, kind } = props

  const curriculumLabel = useLabel("curriculum")
  const classes = useStyles()

  const config = EXPERIENCE_TEMPLATES[kind]
  if (!config) return null
  const { cover, description, apps } = config

  return (
    <Grid container spacing={2} data-testid={testid}>
      <Grid item xs={12}>
        <CoverPhoto
          coverPhoto={cover}
          aspectRatio={TEMPLATE_COVER_ASPECT_RATIO}
          loadingBackground
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DiscoText variant={"body-md-600"} marginBottom={1.5}>
          {"Overview"}
        </DiscoText>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {description}
        </DiscoText>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DiscoText variant={"body-md-600"} marginBottom={1.5}>
          {"Pre-installed Apps"}
        </DiscoText>
        <div className={classes.appsList}>
          {apps.map((app) => (
            <DiscoButton
              key={app}
              color={"grey"}
              variant={"outlined"}
              leftIcon={PRE_INSTALLED_APP_TAGS[app].icon || undefined}
              className={classes.preInstalledAppButtons}
            >
              {getTitle(app)}
            </DiscoButton>
          ))}
        </div>
      </Grid>
    </Grid>
  )

  function getTitle(app: string) {
    if (app === "curriculum") return curriculumLabel.singular
    return PRE_INSTALLED_APP_TAGS[app].title
  }
}

const useStyles = makeUseStyles((theme) => ({
  appsList: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  preInstalledAppButtons: {
    "& svg": {
      width: "24px",
      height: "24px",
    },
  },
}))

export default ExperienceTemplateOverview

// FUTURE: add icons here once DiscoIcon is available
const PRE_INSTALLED_APP_TAGS: Record<
  string,
  {
    title: string
    icon: React.ReactElement | null
  }
> = {
  events: {
    title: "Events",
    icon: <AppIcon kind={APP_KIND_ICON.events} />,
  },
  feed: {
    title: "Feed",
    icon: <AppIcon kind={APP_KIND_ICON.posts} />,
  },
  channels: {
    title: "Channels",
    icon: <AppIcon kind={APP_KIND_ICON.chat_channel} />,
  },
  collections: {
    title: "Collections",
    icon: <AppIcon kind={APP_KIND_ICON.collection} />,
  },
  curriculum: {
    title: "Curriculum",
    icon: <AppIcon kind={APP_KIND_ICON.curriculum} />,
  },
}

const TEMPLATE_COVER_ASPECT_RATIO = 672 / 378

export const EXPERIENCE_TEMPLATES: Partial<
  Record<
    ExperienceTemplateKind,
    {
      title: string
      icon: React.FC<React.SVGProps<SVGSVGElement>>
      cover: string
      description: React.ReactElement
      shortDescription: string
      defaults: () => Partial<CreateExperienceFormState>
      apps: (keyof typeof PRE_INSTALLED_APP_TAGS)[]
    }
  >
> = {
  cohort_based_course: {
    title: "Cohort-Based Course",
    cover: cohortBasedCourseCover,
    icon: CohortBasedCourseIcon,
    shortDescription:
      "A social and collaborative learning experience that takes place over a period of days or weeks.",
    defaults: () => {
      const defaultStartDate = startOfWeek(addWeeks(new Date(), 1), {
        weekStartsOn: 1,
      })
      const defaultEndDate = addWeeks(defaultStartDate, 4)
      return {
        registrationAvailability: "public",
        startDate: format(defaultStartDate, "yyyy-MM-dd"),
        endDate: format(defaultEndDate, "yyyy-MM-dd"),
        isAutoJoined: false,
      }
    },
    description: (
      <>
        <span style={{ fontWeight: 700 }}>{"A Cohort-Based Course"}</span>
        {
          " is a social and collaborative learning experience that takes place over a period of days or weeks. For example, a 6 week course where the entire cohort of Learners all start on Jan 1st."
        }
        <br />
        <br />
        <span style={{ fontWeight: 700 }}>{"This template allows you"}</span>
        {
          " to create a multi-day or multi-week course that includes events, learning modules with pre-recorded videos as well as #channels to engage members."
        }
      </>
    ),
    apps: ["curriculum", "feed", "collections", "events", "channels"],
  },
  self_paced_course: {
    title: "Self-Paced Course",
    cover: selfPacedCourseCover,
    icon: SelfPacedCourseIcon,
    shortDescription:
      "An independent course that members can start at any time and progress through at their own pace.",
    defaults: () => ({
      registrationAvailability: "public",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
    description: (
      <>
        <span style={{ fontWeight: 700 }}>{"A Self-Paced Course"}</span>
        {
          " is an independent learning experience that Learners can start at any time and progress through at their own pace."
        }
        <br />
        <br />
        <span style={{ fontWeight: 700 }}>{"This template will create"}</span>
        {
          " a self-paced course with modules that include lessons, tasks , and assignments."
        }
        <br />
        <br />
        <span style={{ fontWeight: 700 }}>{"Tags:"}</span>
        {" Pre-recorded Course, Asynchronous Course, Evergreen Course"}
      </>
    ),
    apps: ["curriculum"],
  },
  alumni_space: {
    title: "Member Space",
    cover: spaceCover,
    icon: AlumniSpaceIcon,
    shortDescription:
      "A virtual 'place' where members can access community events, resources, and more.",
    defaults: () => ({
      registrationAvailability: "private",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
    description: (
      <>
        <span style={{ fontWeight: 700 }}>{"A Member Space"}</span>
        {
          " is an evergreen area in your community where members go to find content, register for events, and engage in discussions focused on specific topics and themes. A Member Space will be open to all members in your community unless you select otherwise."
        }
        <br />
        <br />
        <span style={{ fontWeight: 700 }}>{"This template allows you"}</span>
        {" engage members through feeds, channels, evergreen resources, and events."}
      </>
    ),
    apps: ["feed", "collections", "events", "channels"],
  },
  series: {
    title: "Event Series",
    cover: seriesCover,
    icon: SeriesIcon,
    shortDescription:
      "A series of events that focus on a specific topic and occur with a regular cadence.",
    defaults: () => {
      const defaultStartDate = startOfWeek(addWeeks(new Date(), 1), {
        weekStartsOn: 1,
      })
      const defaultEndDate = addWeeks(defaultStartDate, 4)
      return {
        registrationAvailability: "public",
        startDate: format(defaultStartDate, "yyyy-MM-dd"),
        endDate: format(defaultEndDate, "yyyy-MM-dd"),
        isAutoJoined: false,
      }
    },
    description: (
      <>
        <span style={{ fontWeight: 700 }}>{"An Event Series"}</span>
        {
          " is a series of events that focus on a specific topicand occur with a regular cadence. For example, an interview series that occurs monthly with guest experts."
        }
        <br />
        <br />
        <span style={{ fontWeight: 700 }}>{"This template allows you"}</span>
        {
          " to create a series of Events and invite existing Members as well as share the event link publicly with new participants."
        }
      </>
    ),
    apps: ["events", "channels", "feed"],
  },
  blank: {
    title: "Blank",
    shortDescription: "Build a product from scratch using Disco Apps.",
    icon: BlankIcon,
    defaults: () => ({
      template: "blank",
      registrationAvailability: "public",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
    // No config for a 'blank' template's preview screen as we don't show it.
    cover: "",
    description: <></>,
    apps: [],
  },
}
