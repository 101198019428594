import useChatBot from "@/admin/integrations/chat-bot/util/useChatBot"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { GlobalID } from "@/relay/RelayTypes"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"

export const NEW_CHAT_BOT_DRAWER = "new"

function useChatBotDrawer() {
  const chatBotDrawer = useGlobalDrawer("chatBot")
  const chatBot = useChatBot()
  const isMobile = useIsMobile()
  const subscriptionStanding = useSubscriptionStanding()

  return {
    isOpen:
      chatBot && !isMobile && subscriptionStanding === "active" && chatBotDrawer.isOpen,
    close: chatBotDrawer.close,
    chatChannelId: chatBotDrawer.params.cb === "new" ? null : chatBotDrawer.params.cb,
    open: (chatChannelId?: GlobalID) =>
      chatBotDrawer.open({ cb: chatChannelId || "new" }),
    changeChannel: (chatChannelId: GlobalID) =>
      chatBotDrawer.setParams({ cb: chatChannelId }),
    newChannel: () => {
      chatBotDrawer.setParams({ cb: "new" })
    },
  }
}

export default useChatBotDrawer
