/** A context that exposes if its children relate are specific to a product or a community. */

import { UpdateAssetTrackFormFragment$key } from "@/admin/asset/tracks/__generated__/UpdateAssetTrackFormFragment.graphql"
import React, { useContext, useState } from "react"

type AssetSubtitlesModalView = "create" | "update"

interface AssetSubtitlesModalState {
  updateAssetTrackFormFragment?: UpdateAssetTrackFormFragment$key | null
  view: AssetSubtitlesModalView | null
}

export type AssetSubtitlesModalContextValue = {
  modalState: AssetSubtitlesModalState | null
  setModalState: React.Dispatch<React.SetStateAction<AssetSubtitlesModalState | null>>
  closeModal: () => void
}

const AssetSubtitlesModalContext = React.createContext(
  {} as AssetSubtitlesModalContextValue
)

export function useAssetSubtitlesModal() {
  return useContext(AssetSubtitlesModalContext)
}

export const AssetSubtitlesModalProvider: React.FC = ({ children }) => {
  const [modalState, setModalState] = useState<AssetSubtitlesModalState | null>(null)
  return (
    <AssetSubtitlesModalContext.Provider
      value={{
        modalState,
        setModalState,
        closeModal,
      }}
    >
      {children}
    </AssetSubtitlesModalContext.Provider>
  )

  function closeModal() {
    setModalState(null)
  }
}
