import {
  OrganizationZoomConnectionListItemFragment$data,
  OrganizationZoomConnectionListItemFragment$key,
  ZoomConnectionHumanReadableUserPlanType,
} from "@/admin/integrations/zoom/connection/__generated__/OrganizationZoomConnectionListItemFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DeleteMeetingProviderButton from "@/meeting-provider/common/delete/button/DeleteMeetingProviderButton"
import { GlobalID } from "@/relay/RelayTypes"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-fill-light.svg"
import WarningIcon from "@assets/disco/icons/color-icons/warning-light.svg"
import { DiscoChip, DiscoTooltip } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { ListItem } from "@material-ui/core"
import React from "react"
import { graphql, useFragment } from "react-relay"

export type ZoomMeetingProviderDataShape = Omit<
  OrganizationZoomConnectionListItemFragment$data,
  " $fragmentType"
>

export type OrganizationZoomConnectionListItemProps = {
  meetingProviderKey?: OrganizationZoomConnectionListItemFragment$key
  meetingProviderData?: ZoomMeetingProviderDataShape
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    meetingProvider: ZoomMeetingProviderDataShape
  ) => void
  overflowOptions?: React.ReactNode[]
  hideOverflow?: boolean
  hideStatus?: boolean
  selectedZoomProviderId?: GlobalID
  rightSideContent?: React.ReactNode
}

const OrganizationZoomConnectionListItem = React.forwardRef<
  HTMLDivElement,
  OrganizationZoomConnectionListItemProps
>(
  (
    {
      meetingProviderKey,
      meetingProviderData,
      onClick,
      hideOverflow,
      hideStatus,
      selectedZoomProviderId,
      rightSideContent,
    },
    ref
  ) => {
    if (!meetingProviderKey && !meetingProviderData)
      throw new Error("Must pass one of either meetingProviderKey or meetingProviderData")

    const permissions = useActiveOrganization()?.viewerPermissions

    let meetingProvider: ZoomMeetingProviderDataShape | null =
      useFragment<OrganizationZoomConnectionListItemFragment$key>(
        graphql`
          fragment OrganizationZoomConnectionListItemFragment on MeetingProvider {
            ...OrganizationZoomConnectionListItem_meetingProviderFragment
              @relay(mask: false)
          }
        `,
        meetingProviderKey || null
      )
    if (!meetingProvider) {
      meetingProvider = meetingProviderData!
    }

    const isSelected = selectedZoomProviderId === meetingProvider?.id
    const classes = useStyles({ isSelected })

    if (!meetingProvider?.zoomConnection) return null
    const {
      zoomConnection: {
        email,
        userPlanType,
        hasWebinarAddon,
        isConnected,
        meetingCapacity,
      },
    } = meetingProvider

    return (
      <ListItem
        innerRef={ref}
        className={classes.paper}
        data-testid={`OrganizationZoomConnectionListItem.${email}`}
        // if we pass an onclick, make the ListItem a button (using underlying 'div' component and ButtonBase)
        // see https://v4.mui.com/api/list-item/
        // types are not working correctly here, not able to conditionally pass button prop
        // @ts-expect-error next-line
        button={Boolean(onClick)}
        onClick={(e) => onClick && onClick(e, meetingProvider!)}
        marginTop={1.5}
      >
        <div
          className={classes.itemContainer}
          data-testid={`OrganizationZoomConnectionListItem.${email}.${userPlanType}`}
        >
          {/* Profile */}
          <ProfileAvatarWithDetails
            userKey={meetingProvider.user}
            testid={"ZoomConnection"}
            details={email}
            color={isSelected ? "common.white" : undefined}
            linkToProfile={false}
          />

          {/* Plan Type */}
          <DiscoTooltip
            content={getPlanTypeTooltipContent(userPlanType)}
            placement={"bottom"}
          >
            <DiscoChip
              className={classes.planType}
              label={`${userPlanType}${hasWebinarAddon ? " with Webinar Add-on" : ""}`}
              classes={{ label: classes.planChipLabel }}
            />
          </DiscoTooltip>

          {/* Connected/Disconnected */}
          {!hideStatus && (
            <DiscoTooltip
              content={
                isConnected
                  ? "Connected"
                  : 'Disconnected - to fix this, a Community Admin must "Add Account" under Integrations > Zoom and connect this account again.'
              }
              placement={"bottom"}
            >
              <div className={classes.status}>
                {isConnected ? (
                  <CheckmarkIcon width={24} height={24} />
                ) : (
                  <WarningIcon width={24} height={24} />
                )}
              </div>
            </DiscoTooltip>
          )}

          {rightSideContent}

          {/* More Actions Dropdown */}
          {permissions?.has("integrations.manage") && !hideOverflow && (
            <DiscoMoreActionsDropdown
              testid={"ZoomConnectionMoreActions"}
              menuButtonTooltipProps={{
                placement: "bottom",
              }}
              moreActionsButtonClassName={classes.moreActionsButton}
            >
              <DeleteMeetingProviderButton meetingProviderId={meetingProvider.id}>
                {(props) => <DiscoDropdownItem title={"Disconnect"} {...props} />}
              </DeleteMeetingProviderButton>
            </DiscoMoreActionsDropdown>
          )}
        </div>
      </ListItem>
    )

    function getPlanTypeTooltipContent(
      planType: ZoomConnectionHumanReadableUserPlanType | null
    ) {
      if (!meetingProvider) return "Unknown plan type"
      switch (planType) {
        case "basic":
          return `You can host Meetings up to ${meetingCapacity} participants.`
        case "licensed":
        case "premium":
          return `You can host Meetings up to ${meetingCapacity} participants${
            hasWebinarAddon
              ? ` and Webinars up to ${meetingProvider.zoomConnection
                  .webinarCapacity!} participants`
              : ""
          }.`
        default:
          return "Unknown plan type"
      }
    }
  }
)

type StyleProps = {
  isSelected?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  paper: ({ isSelected }: StyleProps) => ({
    width: "unset",
    padding: theme.spacing(2),
    background: isSelected ? theme.palette.primary.main : theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
    listStyle: "none",
    boxShadow: theme.palette.groovyDepths.insideCard,
    margin: theme.spacing(1.5, 0.375, 0),

    "&:hover": {
      background: isSelected ? theme.palette.primary.main : "rgba(0,0 ,0, 0.04)",
    },
  }),
  planType: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  planChipLabel: {
    overflow: "unset",
  },
  status: {
    height: "24px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  moreActionsButton: ({ isSelected }: StyleProps) => ({
    "& svg": {
      color: isSelected ? theme.palette.common.white : theme.palette.constants.icon,
    },
  }),
}))

export default OrganizationZoomConnectionListItem

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment OrganizationZoomConnectionListItem_meetingProviderFragment on MeetingProvider {
    id
    zoomConnection {
      id
      email
      firstName
      lastName
      picUrl
      userPlanType
      hasWebinarAddon
      meetingCapacity
      webinarCapacity
      isConnected
    }
    organization {
      id
      hasZoomIntegration
    }
    user {
      id
      ...ProfileAvatarWithDetailsFragment
    }
  }
`
