import {
  AutomationKind,
  AutomationStatus,
  AutomationStepActionKind,
  AutomationStepKind,
  AutomationStepWarningKind,
  AutomationTemplateKind,
  AutomationTriggerKind,
  AutomationTriggerProfileFieldMetadataInput,
  FilterCondition,
  MessageTemplateKind,
  UpdateAutomationBranchInput,
  UpdateAutomationFilterInput,
  UpdateAutomationInput,
  UpdateAutomationStepInput,
  UpdateAutomationTriggerScheduleInput,
} from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import { automationUtils_TemplatesQuery } from "@/admin/automation/utils/__generated__/automationUtils_TemplatesQuery.graphql"
import { automationUtils_useGetDefaultChatChannelSendAsFragment$key } from "@/admin/automation/utils/__generated__/automationUtils_useGetDefaultChatChannelSendAsFragment.graphql"
import { automationUtils_useGetDefaultSlackMessageSendAsFragment$key } from "@/admin/automation/utils/__generated__/automationUtils_useGetDefaultSlackMessageSendAsFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel, useLabels } from "@/core/context/LabelsContext"
import { ObservableState } from "@/core/form/store/FormStore"
import discoBadge from "@/core/ui/images/automations/disco-badge.png"
import emailBadge from "@/core/ui/images/automations/email-badge.png"
import slackBadge from "@/core/ui/images/automations/slack-badge.png"
import Relay from "@/relay/relayUtils"
import { appTheme } from "@assets/style/appMuiTheme"
import EditorUtils from "@components/editor/EditorUtils"
import { $createButtonNode } from "@components/editor/plugins/button/ButtonNode"
import { DiscoIcon, GroovyTextColorKind } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { $createParagraphNode, $createTextNode, SerializedEditorState } from "lexical"
import { capitalize, unset } from "lodash"
import React, { ReactNode } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { v4 as uuidv4 } from "uuid"

export type AutomationFormState = Omit<UpdateAutomationInput, "steps"> & {
  steps: AutomationStepFormStateShape[]
}

export type AutomationStepFormStateShape = Omit<UpdateAutomationStepInput, "branches"> & {
  cardId: string
  branches?: AutomationBranchFormStateShape[] | null
  warningKind?: AutomationStepWarningKind | null
}
export type AutomationStepFormState = ObservableState<AutomationStepFormStateShape>

type AutomationBranchFormStateShape = Omit<
  UpdateAutomationBranchInput,
  "filters" | "steps" | "color"
> & {
  cardId: string
  filters: AutomationFilterFormState[]
  steps: AutomationStepFormStateShape[]
  color: NonNullable<UpdateAutomationBranchInput["color"]>
}
export type AutomationBranchFormState = ObservableState<AutomationBranchFormStateShape>

export type AutomationFilterFormState = UpdateAutomationFilterInput & {
  cardId: string
}

export function useDisabledAutomationActions() {
  const disabledActions: {
    action: AutomationStepActionKind
    reason: string
  }[] = []

  // If no Slack connection, add it to the disabled list
  const activeOrganization = useActiveOrganization()!
  if (!activeOrganization.slackConnectionStatus) {
    disabledActions.push(
      {
        action: "send_slack_direct_message",
        reason: "You must connect Slack to use this action.",
      },
      {
        action: "add_to_slack_channel",
        reason: "You must connect Slack to use this action.",
      },
      {
        action: "remove_from_slack_channel",
        reason: "You must connect Slack to use this action.",
      }
    )
  }

  // If direct messaged is disabled at the community level
  if (!activeOrganization.isDmEnabled) {
    disabledActions.push({
      action: "send_chat_direct_message",
      reason: "You must enable direct messaging to use this action",
    })
  }

  return disabledActions
}

export const AUTOMATION_TRIGGER_KINDS: AutomationTriggerKind[] = [
  "member_leaving_community",
  "member_joined_community",
  "member_onboarding_completed",
  "module_completed",
  "member_joined_course",
  "member_left_course",
  "member_completed_product",
  "product_ended",
  "profile_field_changed",
  // Bring "scheduled" back in the future: https://app.clickup.com/t/14217830/PROD-1992
  // "scheduled",
  "member_joined_event",
  "member_event_rsvp",
  "member_joined_group",
  "member_removed_group",
]

export function useAutomationTriggers(): {
  category: string
  kind: AutomationTriggerKind
}[] {
  const labels = useLabels()
  return [
    { category: "Community", kind: "member_joined_community" },
    { category: "Community", kind: "member_leaving_community" },
    { category: "Community", kind: "member_onboarding_completed" },
    { category: labels.admin_experience.plural, kind: "member_joined_course" },
    { category: labels.admin_experience.plural, kind: "member_left_course" },
    { category: labels.admin_experience.plural, kind: "member_completed_product" },
    { category: labels.admin_experience.plural, kind: "module_completed" },
    { category: labels.admin_experience.plural, kind: "product_ended" },
    { category: labels.admin_member.plural, kind: "member_joined_group" },
    { category: labels.admin_member.plural, kind: "member_removed_group" },
    { category: labels.admin_member.plural, kind: "profile_field_changed" },
    { category: "Events", kind: "member_event_rsvp" },
    { category: "Events", kind: "member_joined_event" },
    // { category: "Time Based", kind: "scheduled" },
  ]
}

export const AUTOMATION_STEP_ACTION_KINDS: AutomationStepActionKind[] = [
  "send_email",
  "send_chat_direct_message",
  "send_chat_channel_message",
  "send_slack_direct_message",
  "add_to_slack_channel",
  "remove_from_slack_channel",
  "add_to_product",
  "remove_from_product",
  "add_to_group",
  "remove_from_group",
]

export type AutomationStepNonActionKind = Exclude<AutomationStepKind, "action">

export const AUTOMATION_STEP_NON_ACTION_KINDS: AutomationStepNonActionKind[] = [
  "branch",
  "delay",
]

export function useAutomationActionCategories(): {
  title: string
  kinds: AutomationStepActionKind[]
}[] {
  const labels = useLabels()
  return [
    {
      title: "Message Actions",
      kinds: [
        "send_email",
        "send_chat_direct_message",
        "send_chat_channel_message",
        "send_slack_direct_message",
        "add_to_slack_channel",
        "remove_from_slack_channel",
      ],
    },
    {
      title: `${labels.admin_experience.singular} Actions`,
      kinds: ["add_to_product", "remove_from_product"],
    },
    {
      title: `${labels.admin_member.singular} Actions`,
      kinds: ["add_to_group", "remove_from_group"],
    },
  ]
}

type AutomationLabel = { title: string; subtitle: string }
export function useAutomationLabels(): {
  triggerLabels: Record<AutomationTriggerKind, AutomationLabel>
  stepActionLabels: Record<AutomationStepActionKind, AutomationLabel>
  stepNonActionLabels: Record<AutomationStepNonActionKind, AutomationLabel>
} {
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  return {
    triggerLabels: {
      module_completed: {
        title: `${membersLabel.singular} completes a module`,
        subtitle: `When a module has been completed by a ${membersLabel.singular}.`,
      },
      member_onboarding_completed: {
        title: `${capitalize(membersLabel.singular)} onboarding completed`,
        subtitle: `When a ${membersLabel.singular} completes the community onboarding.`,
      },
      member_joined_course: {
        title: `${membersLabel.singular} joins a ${experienceLabel.singular}`,
        subtitle: `When a ${membersLabel.singular} joins the ${experienceLabel.singular}.`,
      },
      member_left_course: {
        title: `${membersLabel.singular} leaves a ${experienceLabel.singular}`,
        subtitle: `When a ${membersLabel.singular} leaves the ${experienceLabel.singular}.`,
      },
      member_completed_product: {
        title: `${membersLabel.singular} completes a ${experienceLabel.singular}`,
        subtitle: `When a ${membersLabel.singular} completes a ${experienceLabel.singular}.`,
      },
      product_ended: {
        title: `${experienceLabel.singular} end date`,
        subtitle: `When ${experienceLabel.singular} end date is reached.`,
      },
      scheduled: {
        title: "At a specific time and date",
        subtitle: "Trigger the automation at a specific date and time.",
      },
      member_joined_community: {
        title: `${membersLabel.singular} joins community`,
        subtitle: `When a ${membersLabel.singular} joins the community.`,
      },
      member_leaving_community: {
        title: `${membersLabel.singular} leaves community`,
        subtitle: `When a ${membersLabel.singular} leaves the community.`,
      },
      profile_field_changed: {
        title: `${membersLabel.singular} updates a profile field`,
        subtitle: `When a ${membersLabel.singular} updates a profile field.`,
      },
      member_joined_event: {
        title: `${membersLabel.singular} joins a virtual event`,
        subtitle: `When a ${membersLabel.singular} joins an event.`,
      },
      member_event_rsvp: {
        title: `${membersLabel.singular} confirms attendance to an event`,
        subtitle: `When a ${membersLabel.singular} confirms attendance to an event.`,
      },
      member_joined_group: {
        title: `${membersLabel.singular} joins a group`,
        subtitle: `When any new ${membersLabel.singular} joins the group.`,
      },
      member_removed_group: {
        title: `${membersLabel.singular} leaves a group`,
        subtitle: `When any ${membersLabel.singular} is removed from the group.`,
      },
      "%future added value": {
        title: "",
        subtitle: "",
      },
    },
    stepActionLabels: {
      send_email: {
        title: `Send email message`,
        subtitle: `Send ${membersLabel.singular} an email.`,
      },
      send_slack_direct_message: {
        title: "Send direct message on Slack",
        subtitle: `Send ${membersLabel.singular} a Slack message`,
      },
      add_to_slack_channel: {
        title: "Add to Slack channel",
        subtitle: `Add a ${membersLabel.singular} to a Slack channel`,
      },
      remove_from_slack_channel: {
        title: "Remove from Slack channel",
        subtitle: `Remove a ${membersLabel.singular} from a Slack channel`,
      },
      add_to_group: {
        title: "Add to group",
        subtitle: `Add a ${membersLabel.singular} to a community group`,
      },
      remove_from_group: {
        title: "Remove from group",
        subtitle: `Remove a ${membersLabel.singular} from a community group`,
      },
      add_to_product: {
        title: `Add to ${experienceLabel.singular}`,
        subtitle: `Add a ${membersLabel.singular} to a ${experienceLabel.singular}`,
      },
      remove_from_product: {
        title: `Remove from ${experienceLabel.singular}`,
        subtitle: `Remove a ${membersLabel.singular} from a ${experienceLabel.singular}`,
      },
      send_chat_channel_message: {
        title: "Send Disco channel message",
        subtitle: `Send a message to a chat channel on Disco`,
      },
      send_chat_direct_message: {
        title: "Send Disco direct message",
        subtitle: `Send a direct message on Disco`,
      },
      release_certificate: {
        title: "Release certificate",
        subtitle: "Release a certificate to a user",
      },
      "%future added value": {
        title: "",
        subtitle: "",
      },
    },
    stepNonActionLabels: {
      delay: {
        title: "Delay before continuing",
        subtitle: "Pause for a specified amount of time before continuing.",
      },
      branch: {
        title: "Conditional Branch",
        subtitle: "Conditionally run one of multiple branches of steps",
      },
      "%future added value": {
        title: "",
        subtitle: "",
      },
    },
  }
}

export function useAutomationFormDefaults(
  organization:
    | automationUtils_useGetDefaultSlackMessageSendAsFragment$key
    | automationUtils_useGetDefaultChatChannelSendAsFragment$key
): {
  stepKind: Record<AutomationStepKind, Partial<AutomationStepFormStateShape>>
  stepActionKind: Record<AutomationStepActionKind, Partial<AutomationStepFormStateShape>>
} {
  const defaultSlackMessageSendAs = useGetDefaultSlackMessageSendAs(
    organization as automationUtils_useGetDefaultSlackMessageSendAsFragment$key
  )
  const defaultChatChannelSendAs = useGetDefaultChatChannelSendAs(
    organization as automationUtils_useGetDefaultChatChannelSendAsFragment$key
  )

  return {
    stepKind: {
      delay: {
        kind: "delay",
        delay: "1d",
      },
      // Handled by the STEP ACTION DEFAULT AUTOMATION
      action: {
        actionKind: "%future added value",
        filters: undefined,
        id: undefined,
        kind: "%future added value",
        messageTemplate: undefined,
      },
      branch: {
        kind: "branch",
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
        branches: [],
      },
      "%future added value": {
        actionKind: "%future added value",
        filters: undefined,
        id: undefined,
        kind: "%future added value",
        messageTemplate: undefined,
      },
    },
    stepActionKind: {
      send_email: {
        kind: "action",
        actionKind: "send_email",
        messageTemplate: {
          name: "Send Email",
          kind: "custom",
          emailContent: {
            send: true,
            subject: "",
            richBody: JSON.stringify(EditorUtils.createParagraphs([""])),
          },
        },
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
      send_slack_direct_message: {
        kind: "action",
        actionKind: "send_slack_direct_message",
        messageTemplate: {
          name: "Send Slack Direct Message",
          kind: "custom",
          slackContent: {
            send: true,
            sendAs: defaultSlackMessageSendAs,
            richBody: JSON.stringify(EditorUtils.createParagraphs([""])),
          },
        },
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      add_to_slack_channel: {
        kind: "action",
        actionKind: "add_to_slack_channel",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      remove_from_slack_channel: {
        kind: "action",
        actionKind: "remove_from_slack_channel",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      add_to_group: {
        kind: "action",
        actionKind: "add_to_group",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
      remove_from_group: {
        kind: "action",
        actionKind: "remove_from_group",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
      add_to_product: {
        kind: "action",
        actionKind: "add_to_product",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
      remove_from_product: {
        kind: "action",
        actionKind: "remove_from_product",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
      send_chat_channel_message: {
        kind: "action",
        actionKind: "send_chat_channel_message",
        messageTemplate: {
          name: "Send Disco Channel Message",
          kind: "custom",
          chatContent: {
            send: true,
            sendAs: defaultChatChannelSendAs,
            richBody: JSON.stringify(EditorUtils.createParagraphs([""])),
          },
        },
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      send_chat_direct_message: {
        kind: "action",
        actionKind: "send_chat_direct_message",
        messageTemplate: {
          name: "Send Disco Direct Message",
          kind: "custom",
          chatContent: {
            send: true,
            sendAs: defaultChatChannelSendAs,
            richBody: JSON.stringify(EditorUtils.createParagraphs([""])),
          },
        },
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      release_certificate: {
        kind: "action",
        actionKind: "release_certificate",
        messageTemplate: null,
        filters: [
          {
            level: "step",
            condition: { entity: "audience_member", rule: "is_trigger_user" },
          },
        ],
      },
      "%future added value": {},
    },
  }
}

const iconPadding = 0.25

export const AUTOMATION_ICONS: Record<
  AutomationTriggerKind | AutomationStepActionKind | AutomationStepNonActionKind,
  { icon: ReactNode; color?: GroovyTextColorKind } | null
> = {
  // Triggers
  module_completed: {
    icon: <DiscoIcon icon={"file-check"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  member_onboarding_completed: {
    icon: <DiscoIcon icon={"user"} padding={iconPadding} />,
    color: "groovy.yellow.400",
  },
  member_joined_course: {
    icon: <DiscoIcon icon={"user-add"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  member_left_course: {
    icon: <DiscoIcon icon={"user-remove"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  member_completed_product: {
    icon: <DiscoIcon icon={"book-check"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  product_ended: {
    icon: <DiscoIcon icon={"calendar-check-alt"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  scheduled: {
    icon: <DiscoIcon icon={"time"} padding={iconPadding} />,
    color: "groovy.blue.300",
  },
  member_joined_community: {
    icon: <DiscoIcon icon={"globe"} padding={iconPadding} />,
    color: "groovy.yellow.400",
  },
  member_leaving_community: {
    icon: <DiscoIcon icon={"log-in"} padding={iconPadding} />,
    color: "groovy.yellow.400",
  },
  profile_field_changed: {
    icon: <DiscoIcon icon={"align-center"} padding={iconPadding} />,
    color: "groovy.purple.300",
  },
  member_joined_event: {
    icon: <DiscoIcon icon={"calendar"} padding={iconPadding} />,
    color: "groovy.cyan.400",
  },
  member_event_rsvp: {
    icon: <DiscoIcon icon={"calendar-check-alt"} padding={iconPadding} />,
    color: "groovy.cyan.400",
  },
  member_joined_group: {
    icon: <DiscoIcon icon={"group-share"} padding={iconPadding} />,
    color: "groovy.purple.300",
  },
  member_removed_group: {
    icon: <DiscoIcon icon={"delete"} padding={iconPadding} />,
    color: "groovy.purple.300",
  },

  // Action Steps
  send_email: {
    icon: <DiscoIcon icon={"email"} padding={iconPadding} />,
    color: "groovy.green.400",
  },
  send_slack_direct_message: { icon: <DiscoImage src={slackBadge} /> },
  add_to_slack_channel: { icon: <DiscoImage src={slackBadge} /> },
  remove_from_slack_channel: { icon: <DiscoImage src={slackBadge} /> },
  add_to_group: {
    icon: <DiscoIcon icon={"group-share"} padding={iconPadding} />,
    color: "groovy.purple.300",
  },
  remove_from_group: {
    icon: <DiscoIcon icon={"delete"} padding={iconPadding} />,
    color: "groovy.purple.300",
  },
  add_to_product: {
    icon: <DiscoIcon icon={"user-add"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  remove_from_product: {
    icon: <DiscoIcon icon={"user-remove"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },
  send_chat_channel_message: {
    icon: <DiscoIcon icon={"message"} padding={iconPadding} />,
    color: "groovy.cyan.400",
  },
  send_chat_direct_message: {
    icon: <DiscoIcon icon={"message"} padding={iconPadding} />,
    color: "groovy.cyan.400",
  },
  release_certificate: {
    icon: <DiscoIcon icon={"achievement"} padding={iconPadding} />,
    color: "groovy.orange.400",
  },

  // Non-Action Steps
  branch: {
    icon: <DiscoIcon icon={"filter-funnel"} padding={iconPadding} />,
    color: "groovy.yellow.400",
  },
  delay: {
    icon: <DiscoIcon icon={"timer"} padding={iconPadding} />,
    color: "groovy.yellow.400",
  },

  "%future added value": null,
}

export function useAutomationTemplates(): Record<
  AutomationTemplateKind,
  AutomationTemplate
> | null {
  const activeOrganization = useActiveOrganization()
  const { organization } = useLazyLoadQuery<automationUtils_TemplatesQuery>(
    graphql`
      query automationUtils_TemplatesQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            automationTemplates
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
    }
  )

  if (!organization?.automationTemplates) return null

  return JSON.parse(organization.automationTemplates)
}

export type AutomationTemplate = {
  automation: {
    name: string
    status: AutomationStatus
    kind: AutomationKind
  }
  triggers: {
    kind: AutomationTriggerKind
    metadata?: {
      moduleIds?: string[]
      productIds?: string[]
      occurrenceIds?: string[]
      profileField?: AutomationTriggerProfileFieldMetadataInput
    }
    schedule?: UpdateAutomationTriggerScheduleInput
  }[]
  steps: {
    kind: AutomationStepKind
    actionKind?: AutomationStepActionKind
    messageTemplate: {
      name: string
      kind?: MessageTemplateKind | null
      emailContent?: {
        send: boolean
        subject: string
        richBody: SerializedEditorState
        ccEmails?: string[] | null
        bccEmails?: string[] | null
        ccUserIds?: string[] | null
        bccUserIds?: string[] | null
      } | null
      slackContent?: {
        send: boolean
        sendAs: string | null
        richBody: SerializedEditorState
      } | null
      chatContent?: {
        send: boolean
        sendAs: string | null
        richBody: SerializedEditorState
      } | null
    }
    filters: FilterCondition[]
  }[]
}

type AvailableSteps = "disco" | "slack" | "email"
export const AVAILABLE_STEP_ICONS: Record<AvailableSteps, React.ReactNode> = {
  disco: <DiscoImage src={discoBadge} />,
  slack: <DiscoImage src={slackBadge} />,
  email: <DiscoImage src={emailBadge} />,
}
export const AUTOMATION_TEMPLATE_COVERS: Record<
  AutomationTemplateKind,
  AvailableSteps[]
> = {
  custom: [],
  welcome_to_community: ["disco", "email"],
  welcome_to_course: ["disco", "email"],
  product_completion: ["disco", "email"],
  "%future added value": [],
}

export function useAutomationDescriptions() {
  const { admin_experience, admin_member } = useLabels()

  const AUTOMATION_TEMPLATE_DESCRIPTIONS: Record<AutomationTemplateKind, string> = {
    custom: "Get started on a blank canvas to select your own triggers and actions.",
    welcome_to_community: `Welcome new ${admin_member.plural} to your community with a series of messages.`,
    welcome_to_course: `Welcome new ${admin_member.plural} to your ${admin_experience.singular}.`,
    product_completion: `Send a message upon ${admin_experience.singular} completion. Completion is when the curriculum is completed by the ${admin_member.singular}.`,
    "%future added value": "",
  }

  return { AUTOMATION_TEMPLATE_DESCRIPTIONS }
}

export function useGetDefaultChatChannelSendAs(
  organizationKey: automationUtils_useGetDefaultChatChannelSendAsFragment$key
) {
  const organization =
    useFragment<automationUtils_useGetDefaultChatChannelSendAsFragment$key>(
      graphql`
        fragment automationUtils_useGetDefaultChatChannelSendAsFragment on Organization {
          viewerMembership {
            id
            streamChatUserId
          }
        }
      `,
      organizationKey
    )

  return organization.viewerMembership?.streamChatUserId
}

export function useGetDefaultSlackMessageSendAs(
  organizationKey: automationUtils_useGetDefaultSlackMessageSendAsFragment$key | null
) {
  const organization =
    useFragment<automationUtils_useGetDefaultSlackMessageSendAsFragment$key>(
      graphql`
        fragment automationUtils_useGetDefaultSlackMessageSendAsFragment on Organization {
          omscs: organizationMembershipSlackConnections(
            status: active
            viewerOnly: true
          ) {
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      organizationKey
    )

  const [viewerSlackConnection] = Relay.connectionToArray(organization?.omscs)
  return viewerSlackConnection?.id || null
}

export type AutomationTemplates = Record<
  AutomationTemplateKind,
  Omit<UpdateAutomationInput, "organizationId">
>

export const AUTOMATION_TEMPLATES: AutomationTemplates = {
  custom: {
    name: "",
    status: "draft",
    createdWithTemplate: "custom",
    trigger: {},
    steps: [],
  },
  welcome_to_community: {
    name: "Welcome to Community",
    status: "draft",
    createdWithTemplate: "welcome_to_community",
    trigger: { kind: "member_joined_community" },
    steps: [
      {
        kind: "action",
        actionKind: "send_email",
        messageTemplate: {
          name: "Community Registration",
          kind: "community_welcome",
          emailContent: {
            send: true,
            subject: "Welcome to {{ community.name }}!",
            richBody: JSON.stringify(
              EditorUtils.createData((root) => {
                root.append(
                  $createParagraphNode().append($createTextNode("Hi 👋")),
                  $createParagraphNode().append(
                    $createTextNode("Welcome to {{ community.name }}!")
                  ),
                  $createParagraphNode().append(
                    $createTextNode("Please follow the link below to go to the community")
                  ),
                  $createParagraphNode().append($createTextNode("")),
                  $createButtonNode({
                    text: "Go to Community",
                    linkTo: "home",
                    component: "cta_button",
                  })
                )
              })
            ),
          },
        },
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
    ],
  },
  welcome_to_course: {
    name: "Welcome to Course",
    status: "draft",
    createdWithTemplate: "welcome_to_course",
    trigger: {
      kind: "member_joined_course",
      metadata: {
        productIds: [],
      },
    },
    steps: [
      {
        kind: "action",
        actionKind: "send_email",
        messageTemplate: {
          name: "Space Registration",
          kind: "course_welcome",
          emailContent: {
            send: true,
            subject: "Welcome to {{ product.name }}!",
            richBody: JSON.stringify(
              EditorUtils.createData((root) => {
                root.append(
                  $createParagraphNode().append($createTextNode("Hi 👋")),
                  $createParagraphNode().append(
                    $createTextNode("Welcome to {{ experience.name }}!")
                  ),
                  $createParagraphNode().append(
                    $createTextNode("Please follow the link below to see more:")
                  ),
                  $createParagraphNode().append($createTextNode("")),
                  $createButtonNode({
                    text: "See More",
                    linkTo: "dashboard",
                    component: "cta_button",
                  })
                )
              })
            ),
          },
        },
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
    ],
  },
  product_completion: {
    name: "Product Completion",
    status: "draft",
    createdWithTemplate: "product_completion",
    trigger: {
      kind: "member_completed_product",
      metadata: {
        productIds: [],
      },
    },
    steps: [
      {
        kind: "action",
        actionKind: "send_email",
        messageTemplate: {
          name: "Product Completion",
          kind: "product_completion",
          emailContent: {
            send: true,
            subject: "Congrats on completing {{ product.name }}!",
            richBody: JSON.stringify(
              EditorUtils.createData((root) => {
                root.append(
                  $createParagraphNode().append($createTextNode("You did it! 🎉")),
                  $createParagraphNode().append(
                    $createTextNode(
                      "You've successfully completed {{ experience.name }}!"
                    )
                  ),
                  $createParagraphNode().append($createTextNode("")),
                  $createButtonNode({
                    text: "See More",
                    linkTo: "dashboard",
                    component: "cta_button",
                  })
                )
              })
            ),
          },
        },
        filters: [
          {
            level: "step",
            condition: {
              entity: "audience_member",
              rule: "is_trigger_user",
            },
          },
        ],
      },
    ],
  },
  "%future added value": {
    automationId: undefined,
    createdWithTemplate: undefined,
    name: undefined,
    status: undefined,
    steps: undefined,
    trigger: undefined,
  },
}

export function resetAutomationStepIds(
  cloneStep: AutomationStepFormState,
  openCard: (id: string) => void
) {
  // Remove all ID fields
  unset(cloneStep, "id")
  cloneStep.filters?.forEach((filter) => unset(filter, "id"))
  unset(cloneStep, "messageTemplate.id")
  // Remove kind since we're creating a new template
  unset(cloneStep, "messageTemplate.kind")
  // New unique cardId
  cloneStep.cardId = uuidv4()
  openCard(cloneStep.cardId)

  // Clone the step's branches if any
  cloneStep.branches?.forEach((branch) => resetAutomationBranchIds(branch, openCard))
}

export function resetAutomationBranchIds(
  cloneBranch: AutomationBranchFormState,
  openCard: (id: string) => void
) {
  unset(cloneBranch, "id")
  cloneBranch.cardId = uuidv4()
  cloneBranch.filters.forEach((filter) => {
    unset(filter, "id")
    filter.cardId = uuidv4()
  })
  cloneBranch.steps.forEach((step) => resetAutomationStepIds(step, openCard))
}

export const AUTOMATION_BRANCH_COLORS = [
  appTheme.palette.groovy.blue[400],
  appTheme.palette.groovy.red[400],
  appTheme.palette.groovy.green[600],
  appTheme.palette.groovy.yellow[500],
  appTheme.palette.groovy.purple[400],
  appTheme.palette.groovy.orange[400],
  appTheme.palette.groovy.pink[500],
  appTheme.palette.groovy.cyan[400],
  appTheme.palette.groovy.green[400],
  appTheme.palette.groovy.onDark[400],
]

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment automationUtils_UpdateAutomationFragment on Automation {
    id
    status
    name
    kind
    ...AutomationsReportsTableRow_Automation
    triggers {
      edges {
        node {
          id
          kind
          metadata {
            moduleIds
            productIds
            profileField {
              id
              value
              optionIds
            }
            occurrenceIds
            memberGroupIds
          }
          schedule: automationTriggerSchedule {
            id
            triggerAt
          }
        }
      }
    }
    steps {
      totalCount
      edges {
        node {
          ...automationUtils_UpdateAutomationStepFragment @relay(mask: false)
          ...automationUtils_UpdateAutomationStepBranchesFragment @relay(mask: false)
        }
      }
    }
  }
`

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment automationUtils_UpdateAutomationStepFragment on AutomationStep {
    id
    kind
    actionKind
    delay
    actionMemberGroupIds
    actionChannels
    actionProductIds
    warningKind
    filters {
      edges {
        node {
          id
          level
          condition
        }
      }
    }
    actionMessageTemplate {
      id
      name
      kind
      emailContent {
        send
        subject
        richBody
        ccEmails
        bccEmails
        ccUserIds
        bccUserIds
      }
      slackContent {
        send
        sendAs
        richBody
      }
      chatContent {
        send
        sendAs
        richBody
      }
    }
  }
`

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment automationUtils_UpdateAutomationStepBranchesFragment on AutomationStep {
    branches {
      edges {
        node {
          id
          name
          color
          filterOperator
          filters {
            edges {
              node {
                id
                level
                condition
              }
            }
          }
          steps {
            edges {
              node {
                ...automationUtils_UpdateAutomationStepFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  }
`
