import { useCopilotBotFragment$key } from "@/admin/integrations/copilot/util/__generated__/useCopilotBotFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function useCopilotBot() {
  const activeOrganization = useActiveOrganization()!
  const organization = useFragment<useCopilotBotFragment$key>(
    graphql`
      fragment useCopilotBotFragment on Organization {
        copilotBot {
          id
          name
          status
        }
      }
    `,
    activeOrganization
  )

  return organization.copilotBot
}

export default useCopilotBot
