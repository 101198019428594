import SlackUserImportPageHeader from "@/admin/integrations/slack/setup/SlackUserImportPageHeader"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CreateSlackUserImportButton from "@/slack/create-slack-user-import-button/CreateSlackUserImportButton"
import { DiscoButton } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"

type Props = {
  isOnboarding?: boolean
}

function SlackUserImportFailureStepContent({ isOnboarding }: Props) {
  const theme = useTheme()
  return (
    <>
      <SlackUserImportPageHeader
        title={"Final Connection Step Failed"}
        subtitle={
          "An unexpected error occurred while attempting to complete the final Slack connection step. Not to worry! You can restart the step below, or skip this step and continue to your community."
        }
      />
      <Grid
        container
        justifyContent={"center"}
        style={{ gap: theme.spacing(0.5), width: "fit-content" }}
        direction={"column"}
      >
        {/* reload so we start polling again */}
        <CreateSlackUserImportButton onSuccess={() => location.reload()}>
          {(renderProps) => <DiscoButton {...renderProps}>{"Retry"}</DiscoButton>}
        </CreateSlackUserImportButton>
        <DiscoButton
          to={
            isOnboarding
              ? ROUTE_NAMES.ADMIN.DASHBOARD
              : ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT
          }
          color={"grey"}
          variant={"outlined"}
        >
          {"Skip and Continue"}
        </DiscoButton>
      </Grid>
    </>
  )
}

export default SlackUserImportFailureStepContent
