import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const RoleDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "role-drawer-content" */ "@/admin/members/roles/drawer/RoleDrawerContent"
    )
)

export default function RoleDrawer() {
  const drawer = useGlobalDrawer("role")
  const classes = useStyles()
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"large"}
      onClose={handleClose}
      containerClasses={{ drawerContainer: classes.drawer }}
    >
      <UnsavedChangesModalProvider {...unsavedChangesModal}>
        {drawer.isOpen && <RoleDrawerContent />}
      </UnsavedChangesModalProvider>
    </DiscoDrawer>
  )

  function handleClose() {
    unsavedChangesModal.handleLeave({
      onLeave: drawer.close,
    })
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
  },
})
