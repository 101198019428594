import CreateExperienceModal from "@/admin/experiences/create/CreateExperienceModal"
import ExperienceTemplateCard, {
  ExperienceTemplateCardSkeleton,
} from "@/admin/experiences/create/ExperienceTemplateCard"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoCarousel, { DiscoCarouselSkeleton } from "@disco-ui/carousel/DiscoCarousel"
import { useState } from "react"

function ExperienceTemplateCarousel() {
  const classes = useStyles()
  const labels = useLabels()

  const [state, setState] = useState<{
    template: ExperienceTemplateKind
    step: "preview" | "use"
  } | null>(null)

  return (
    <>
      <DiscoCarousel
        className={classes.container}
        title={`${labels.admin_experience.singular} Templates`}
        data={EXPERIENCE_TEMPLATE_CARDS_ORDER}
        item={({ template }) => (
          <ExperienceTemplateCard
            key={template}
            template={template}
            onUse={() =>
              setState({ template, step: template === "blank" ? "use" : "preview" })
            }
            inCarousel
          />
        )}
        totalCount={EXPERIENCE_TEMPLATE_CARDS_ORDER.length}
        itemSkeleton={<ExperienceTemplateCardSkeleton />}
      />

      {state?.template && (
        <CreateExperienceModal
          showPreview={state.step === "preview"}
          template={state.template}
          onClose={() => setState(null)}
        />
      )}
    </>
  )
}

export const EXPERIENCE_TEMPLATE_CARDS_ORDER: { template: ExperienceTemplateKind }[] = [
  { template: "blank" },
  { template: "cohort_based_course" },
  { template: "self_paced_course" },
  { template: "series" },
]

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

export function ExperienceTemplateCarouselSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DiscoCarouselSkeleton item={<ExperienceTemplateCardSkeleton />} showTitle />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ExperienceTemplateCarousel,
  skeleton: ExperienceTemplateCarouselSkeleton,
})
