import AdminContentListFilterDropdown from "@/admin/content-library/filters/AdminContentListFilterDropdown"
import { AdminContentListLabelFilterState } from "@/admin/content-library/filters/AdminContentListLabelFilter"
import { AdminContentListProductFilterState } from "@/admin/content-library/filters/AdminContentListProductFilter"
import { ContentType } from "@/admin/content-library/__generated__/AdminContentLibraryListTableRowFragment.graphql"
import { DiscoInput } from "@disco-ui"
import DiscoTableSortDropdown, {
  DiscoTableSortDirection,
  DiscoTableSortOption,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import useDebounce from "@utils/hook/useDebounce"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { AdminContentListCollectionFilterState } from "./AdminContentListCollectionAppFilter"

const ORDER_BY_OPTIONS: DiscoTableSortOption[] = [
  // TEMP: Hidden for now since we don't surface this data yet
  // {
  //   value: "views",
  //   title: "Total Views",
  //   context: {
  //     order: { field: "views" },
  //   },
  // },
  {
    value: "instances",
    title: "# of Instances",
    context: {
      order: { field: "instances" },
    },
  },
  {
    value: "created_at",
    title: "Creation Date",
    context: {
      order: { field: "created_at" },
    },
  },
]

export type AdminContentListToolbarState = DiscoTableToolbarStateWithSort &
  AdminContentListLabelFilterState &
  AdminContentListProductFilterState &
  AdminContentListCollectionFilterState
interface AdminContentListToolbarProps extends TestIDProps {
  toolbarState: AdminContentListToolbarState
  setToolbarState: React.Dispatch<React.SetStateAction<AdminContentListToolbarState>>
  includeSort?: boolean
  contentType?: ContentType
  hideCollection?: boolean
  hideProduct?: boolean
}

function AdminContentListToolbar({
  testid,
  toolbarState,
  setToolbarState,
  includeSort,
  contentType,
  hideCollection = false,
  hideProduct = false,
}: AdminContentListToolbarProps) {
  const [inputText, setInputText] = useState<string>("")

  const debounceSearchTextChange = useDebounce((newSearchText: string) => {
    setToolbarState((prev) => ({ ...prev, search: newSearchText }))
  }, 750)

  return (
    <>
      <DiscoInput
        data-testid={`${testid}.search-input`}
        onChange={handleSearchTextChange}
        value={inputText}
        placeholder={"Search"}
        fullWidth
      />

      <AdminContentListFilterDropdown
        toolbarState={toolbarState}
        setToolbarState={setToolbarState}
        // Surveys can't be added to collections, and are opened to the organization usage
        // so these filters aren't relevant for them
        hideProduct={contentType === "survey" || hideProduct}
        hideCollection={contentType === "survey" || hideCollection}
      />

      {includeSort && (
        <DiscoTableSortDropdown
          options={ORDER_BY_OPTIONS}
          onOptionSelect={handleOrderBySelect}
          toolbarState={toolbarState}
          testid={"AdminContentListToolbar"}
        />
      )}
    </>
  )

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputText(e.target.value)
    debounceSearchTextChange(e.target.value)
  }

  function handleOrderBySelect(
    sortOption: DiscoTableSortOption,
    dir: DiscoTableSortDirection
  ) {
    setToolbarState({
      ...toolbarState,
      sort: {
        id: sortOption.value,
        order: sortOption.context!.order,
        title: sortOption.title,
      },
      direction: dir,
    })
  }
}

export default AdminContentListToolbar
