import { AssetTrackStatusChipFragment$key } from "@/admin/asset/tracks/__generated__/AssetTrackStatusChipFragment.graphql"
import { DiscoChip } from "@disco-ui"
import { capitalize } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  assetTrackKey: AssetTrackStatusChipFragment$key
}

function AssetTrackStatusChip({ testid, assetTrackKey }: Props) {
  const { muxAssetTrackStatus: status, isAutoGenerated } =
    useFragment<AssetTrackStatusChipFragment$key>(
      graphql`
        fragment AssetTrackStatusChipFragment on AssetTrack {
          muxAssetTrackStatus
          isAutoGenerated
        }
      `,
      assetTrackKey
    )

  const color = getColor()
  const label = getLabel()

  return (
    <DiscoChip
      data-testid={`${testid}.status`}
      color={color}
      label={label}
      tooltip={{
        disabled: status !== "errored" || !isAutoGenerated,
        content:
          "Auto-generated subtitles typically fail to upload when an audio track is not found on the video uploaded",
      }}
    />
  )

  function getColor() {
    if (status === "ready") {
      return "green"
    } else if (status === "errored") {
      return "red"
    }
    return "yellow"
  }

  function getLabel() {
    if (status) {
      return capitalize(status)
    }
    return "Preparing"
  }
}

export default AssetTrackStatusChip
