/**
 * @generated SignedSource<<5d7df1268104f65e1244a200ec64a491>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BadgeFragment$data = {
  readonly kind: BadgeKind;
  readonly color: string | null;
  readonly icon: string | null;
  readonly emoji: string | null;
  readonly mediaUrl: string | null;
  readonly " $fragmentType": "BadgeFragment";
};
export type BadgeFragment$key = {
  readonly " $data"?: BadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    }
  ],
  "type": "Badge",
  "abstractKey": null
};

(node as any).hash = "42e3fc8f2a94f4987ea0418701574694";

export default node;
