/**
 * @generated SignedSource<<fcead1127e9568b8fe9bd8bebe191472>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoomConnectionHumanReadableUserPlanType = "basic" | "licensed" | "premium" | "none" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganizationZoomConnectionListItemFragment$data = {
  readonly id: string;
  readonly zoomConnection: {
    readonly id: string;
    readonly email: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly picUrl: string | null;
    readonly userPlanType: ZoomConnectionHumanReadableUserPlanType;
    readonly hasWebinarAddon: boolean | null;
    readonly meetingCapacity: number | null;
    readonly webinarCapacity: number | null;
    readonly isConnected: boolean;
  };
  readonly organization: {
    readonly id: string;
    readonly hasZoomIntegration: boolean;
  };
  readonly user: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly " $fragmentType": "OrganizationZoomConnectionListItemFragment";
};
export type OrganizationZoomConnectionListItemFragment$key = {
  readonly " $data"?: OrganizationZoomConnectionListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationZoomConnectionListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ZoomConnection",
      "kind": "LinkedField",
      "name": "zoomConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userPlanType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasWebinarAddon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "meetingCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webinarCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isConnected",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasZoomIntegration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingProvider",
  "abstractKey": null
};
})();

(node as any).hash = "6ade34d2225041971b3adebc3aee14b3";

export default node;
