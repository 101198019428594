import { AdminContentListProductFilterFragment$key } from "@/admin/content-library/filters/__generated__/AdminContentListProductFilterFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown, {
  DiscoTableFilterDropdownSkeleton,
} from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type AdminContentListProductOption = SelectOption<GlobalID | null>

export type AdminContentListProductFilterState = {
  selectedProduct: AdminContentListProductOption | null
}

interface AdminContentListProductFilterProps extends TestIDProps {
  filterState: AdminContentListProductFilterState
  setFilterState: (state: AdminContentListProductFilterState) => void
  organizationKey: AdminContentListProductFilterFragment$key
}

function AdminContentListProductFilter(props: AdminContentListProductFilterProps) {
  const { filterState, setFilterState, organizationKey } = props

  const organization = useFragment<AdminContentListProductFilterFragment$key>(
    graphql`
      fragment AdminContentListProductFilterFragment on Organization {
        products(type: "course") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    organizationKey
  )

  const productLabel = useLabel("admin_experience")
  const products = Relay.connectionToArray(organization.products)
  const options: AdminContentListProductOption[] = [
    { value: null, title: `Any ${productLabel.singular}` },
    ...products.map((p) => ({ value: p.id, title: p.name })),
  ]

  const selectedOption = filterState.selectedProduct

  return (
    <DiscoTableFilterDropdown
      testid={"AdminContentList.product-select"}
      isNested
      options={options}
      onOptionSelect={handleSelect}
      selectedOption={selectedOption}
      dropdown={{ label: productLabel.singular, icon: null }}
    />
  )

  function handleSelect(option: AdminContentListProductOption | null) {
    if (option?.value)
      setFilterState({
        ...filterState,
        selectedProduct: option,
      })
    else
      setFilterState({
        ...filterState,
        selectedProduct: null,
      })
  }
}

export default Relay.withSkeleton({
  component: AdminContentListProductFilter,
  skeleton: DiscoTableFilterDropdownSkeleton,
})
