import {
  OrganizationRole,
  ProductRole,
  RoleAvatarStackQuery,
} from "@/admin/members/roles/__generated__/RoleAvatarStackQuery.graphql"
import { RoleAvatarStack_OrganizationRolePaginationFragment$key } from "@/admin/members/roles/__generated__/RoleAvatarStack_OrganizationRolePaginationFragment.graphql"
import { RoleAvatarStack_OrganizationRolePaginationQuery } from "@/admin/members/roles/__generated__/RoleAvatarStack_OrganizationRolePaginationQuery.graphql"
import { RoleAvatarStack_ProductRolePaginationFragment$key } from "@/admin/members/roles/__generated__/RoleAvatarStack_ProductRolePaginationFragment.graphql"
import { RoleAvatarStack_ProductRolePaginationQuery } from "@/admin/members/roles/__generated__/RoleAvatarStack_ProductRolePaginationQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useRoleAdminLabel } from "@/role/roleUtils"
import UserAvatarStack, {
  UserAvatarStackSkeleton,
} from "@/user/common/avatar-stack/UserAvatarStack"
import ProfileListDrawer from "@/user/common/profile-list-drawer/ProfileListDrawer"
import DiscoDrawerHeaderBreadcrumbTitle from "@disco-ui/drawer/DiscoDrawerHeaderBreadcrumbTitle"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import {
  graphql,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { RecordSourceProxy } from "relay-runtime"

type Props = TestIDProps & {
  organizationRole?: OrganizationRole
  productRole?: ProductRole
}

const MEMBERS_PER_LOAD = 15

function RoleAvatarStack({ organizationRole, productRole, testid }: Props) {
  const activeOrganization = useActiveOrganization()!
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const roleLabel = useRoleAdminLabel(organizationRole, productRole)
  const productLabel = useLabel("admin_experience")

  const { organization } = useLazyLoadQuery<RoleAvatarStackQuery>(
    graphql`
      query RoleAvatarStackQuery(
        $id: ID!
        $organizationRoles: [OrganizationRole!]
        $skipOrganization: Boolean!
        $productRoles: [ProductRole!]
        $skipProduct: Boolean!
        $first: Int!
        $after: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...RoleAvatarStack_OrganizationRolePaginationFragment
              @arguments(
                organizationRoles: $organizationRoles
                first: $first
                after: $after
              )
              @skip(if: $skipOrganization)
            ...RoleAvatarStack_ProductRolePaginationFragment
              @arguments(productRoles: $productRoles, first: $first, after: $after)
              @skip(if: $skipProduct)
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      organizationRoles: organizationRole ? [organizationRole] : null,
      skipOrganization: !organizationRole,
      productRoles: productRole ? [productRole] : null,
      skipProduct: !productRole,
      first: MEMBERS_PER_LOAD,
    }
  )

  const orgRolePagination = usePaginationFragment<
    RoleAvatarStack_OrganizationRolePaginationQuery,
    RoleAvatarStack_OrganizationRolePaginationFragment$key
  >(
    graphql`
      fragment RoleAvatarStack_OrganizationRolePaginationFragment on Organization
      @refetchable(queryName: "RoleAvatarStack_OrganizationRolePaginationQuery")
      @argumentDefinitions(
        organizationRoles: { type: "[OrganizationRole!]" }
        first: { type: "Int!" }
        after: { type: "String" }
      ) {
        organizationMemberships(
          roles: $organizationRoles
          first: $first
          after: $after
          orderBy: member_name
          direction: ASC
        ) @connection(key: "RoleAvatarStack__organizationMemberships") {
          __id
          totalCount
          edges {
            node {
              id
              member {
                id
                first_name: firstName
                last_name: lastName
                avatar
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organizationRole ? organization : null
  )

  useSubscribeToInvalidationState(
    orgRolePagination.data?.organizationMemberships?.__id
      ? [orgRolePagination.data.organizationMemberships.__id]
      : [],
    () => {
      orgRolePagination.refetch(
        { first: MEMBERS_PER_LOAD },
        { fetchPolicy: "network-only" }
      )
    }
  )

  const productRolePagination = usePaginationFragment<
    RoleAvatarStack_ProductRolePaginationQuery,
    RoleAvatarStack_ProductRolePaginationFragment$key
  >(
    graphql`
      fragment RoleAvatarStack_ProductRolePaginationFragment on Organization
      @refetchable(queryName: "RoleAvatarStack_ProductRolePaginationQuery")
      @argumentDefinitions(
        productRoles: { type: "[ProductRole!]" }
        first: { type: "Int!" }
        after: { type: "String" }
      ) {
        productRoleUsers(roles: $productRoles, first: $first, after: $after)
          @connection(key: "RoleAvatarStack__productRoleUsers") {
          __id
          totalCount
          edges {
            node {
              id
              first_name: firstName
              last_name: lastName
              avatar
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    productRole ? organization : null
  )

  useSubscribeToInvalidationState(
    productRolePagination.data?.productRoleUsers?.__id
      ? [productRolePagination.data.productRoleUsers.__id]
      : [],
    () => {
      productRolePagination.refetch(
        { first: MEMBERS_PER_LOAD },
        { fetchPolicy: "network-only" }
      )
    }
  )

  const { loadNext, hasNext, isLoadingNext } = organizationRole
    ? orgRolePagination
    : productRolePagination

  let users, totalCount: number
  if (organizationRole) {
    const { data } = orgRolePagination
    users = Relay.connectionToArray(data?.organizationMemberships).map((om) => om.member)
    totalCount = data?.organizationMemberships.totalCount || 0
  } else {
    const { data } = productRolePagination
    users = Relay.connectionToArray(data?.productRoleUsers)
    totalCount = data?.productRoleUsers.totalCount || 0
  }
  if (!users.length) return null

  return (
    <>
      <UserAvatarStack
        testid={`${testid ? `${testid}.` : ""}RoleAvatarStack`}
        users={users}
        totalUsers={totalCount}
        countVariant={"always"}
        textVariant={"body-xs-500"}
        textColor={"text.secondary"}
        onClick={() => setIsDrawerOpen(true)}
        viewUserTooltip={{ content: getTooltip() }}
      />

      <ProfileListDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        membersPerLoad={MEMBERS_PER_LOAD}
        hasNext={hasNext}
        users={users}
        totalCount={totalCount}
        title={
          <DiscoDrawerHeaderBreadcrumbTitle
            mobileIcon={"user"}
            parts={["Role", roleLabel.plural]}
          />
        }
      />
    </>
  )

  function getTooltip() {
    const text = `${totalCount} ${totalCount === 1 ? "person has" : "people have"} the ${
      roleLabel.singular
    } role`
    if (organizationRole) return `${text}.`
    return `${text} in one or more ${productLabel.plural}.`
  }
}

export default Relay.withSkeleton<Props>({
  component: RoleAvatarStack,
  skeleton: () => <UserAvatarStackSkeleton />,
})

// Helper to invalidate the productRoleUsers connection so it refetches
export function invalidateRoleAvatarStackProductRoles(
  store: RecordSourceProxy,
  organizationId: GlobalID
) {
  const organizationRecord = store.get(organizationId)
  if (!organizationRecord) return
  ConnectionHandler.getConnections(
    organizationRecord,
    "RoleAvatarStack__productRoleUsers"
  ).forEach((connection) => connection.invalidateRecord())
}
