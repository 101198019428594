/**
 * @generated SignedSource<<43d648ea1681a93c0d6963bd01ca8735>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetTranscriptionJobStatus = "pending" | "completed" | "failed" | "%future added value";
export type LanguageCode = "en" | "aa" | "ab" | "ae" | "af" | "ak" | "am" | "an" | "ar" | "as" | "av" | "ay" | "az" | "ba" | "be" | "bg" | "bi" | "bm" | "bn" | "bo" | "br" | "bs" | "ca" | "ce" | "ch" | "co" | "cr" | "cs" | "cu" | "cv" | "cy" | "da" | "de" | "dv" | "dz" | "ee" | "el" | "eo" | "es" | "et" | "eu" | "fa" | "ff" | "fi" | "fj" | "fo" | "fr" | "fy" | "ga" | "gd" | "gl" | "gn" | "gu" | "gv" | "ha" | "he" | "hi" | "ho" | "hr" | "ht" | "hu" | "hy" | "hz" | "ia" | "id" | "ie" | "ig" | "ii" | "ik" | "io" | "is" | "it" | "iu" | "ja" | "jv" | "ka" | "kg" | "ki" | "kj" | "kk" | "kl" | "km" | "kn" | "ko" | "kr" | "ks" | "ku" | "kv" | "kw" | "ky" | "la" | "lb" | "lg" | "li" | "ln" | "lo" | "lt" | "lu" | "lv" | "mg" | "mh" | "mi" | "mk" | "ml" | "mn" | "mr" | "ms" | "mt" | "my" | "na" | "nb" | "nd" | "ne" | "ng" | "nl" | "nn" | "no" | "nr" | "nv" | "ny" | "oc" | "oj" | "om" | "or" | "os" | "pa" | "pi" | "pl" | "ps" | "pt" | "qu" | "rm" | "rn" | "ro" | "ru" | "rw" | "sa" | "sc" | "sd" | "se" | "sg" | "si" | "sk" | "sl" | "sm" | "sn" | "so" | "sq" | "sr" | "ss" | "st" | "su" | "sv" | "sw" | "ta" | "te" | "tg" | "th" | "ti" | "tk" | "tl" | "tn" | "to" | "tr" | "ts" | "tt" | "tw" | "ty" | "ug" | "uk" | "ur" | "uz" | "ve" | "vi" | "vo" | "wa" | "wo" | "xh" | "yi" | "yo" | "za" | "zh" | "zu" | "%future added value";
export type UpdateAssetInput = {
  id: string;
  name?: string | null;
  languageCode?: LanguageCode | null;
  enableVideoAssets?: boolean | null;
  productId?: string | null;
  muxAssetAutoGenerateSubtitles?: boolean | null;
};
export type StartAssetTranscriptionJobButtonMutation$variables = {
  input: UpdateAssetInput;
};
export type StartAssetTranscriptionJobButtonMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly transcriptionJob: {
        readonly status: AssetTranscriptionJobStatus;
      } | null;
      readonly transcription: {
        readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerOverviewTabContentFragment" | "InteractivePlayerTranscriptTabContentFragment">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerTranscriptionStatusFragment" | "InteractivePlayerSubtitlesTabContentFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type StartAssetTranscriptionJobButtonMutation = {
  variables: StartAssetTranscriptionJobButtonMutation$variables;
  response: StartAssetTranscriptionJobButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartAssetTranscriptionJobButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssetResponse",
        "kind": "LinkedField",
        "name": "UpdateAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerTranscriptionStatusFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerSubtitlesTabContentFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscriptionJob",
                "kind": "LinkedField",
                "name": "transcriptionJob",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscription",
                "kind": "LinkedField",
                "name": "transcription",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerOverviewTabContentFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerTranscriptTabContentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartAssetTranscriptionJobButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssetResponse",
        "kind": "LinkedField",
        "name": "UpdateAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoAssetsEnabledAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscriptionJob",
                "kind": "LinkedField",
                "name": "transcriptionJob",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sieveJobErrorReason",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscription",
                "kind": "LinkedField",
                "name": "transcription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "summary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTranscriptionChapter",
                    "kind": "LinkedField",
                    "name": "chapters",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timecode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTranscriptionSegment",
                    "kind": "LinkedField",
                    "name": "segments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "start",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTimecode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cac32fc704ace577277c0d17147d71c4",
    "id": null,
    "metadata": {},
    "name": "StartAssetTranscriptionJobButtonMutation",
    "operationKind": "mutation",
    "text": "mutation StartAssetTranscriptionJobButtonMutation(\n  $input: UpdateAssetInput!\n) {\n  response: UpdateAsset(input: $input) {\n    node {\n      id\n      ...InteractivePlayerTranscriptionStatusFragment\n      ...InteractivePlayerSubtitlesTabContentFragment\n      transcriptionJob {\n        status\n        id\n      }\n      transcription {\n        ...InteractivePlayerOverviewTabContentFragment\n        ...InteractivePlayerTranscriptTabContentFragment\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment InteractivePlayerOverviewTabContentFragment on AssetTranscription {\n  id\n  summary\n  chapters {\n    title\n    startTime\n    timecode\n  }\n}\n\nfragment InteractivePlayerSubtitlesTabContentFragment on Asset {\n  id\n}\n\nfragment InteractivePlayerTranscriptTabContentFragment on AssetTranscription {\n  ...TranscriptReaderFragment\n}\n\nfragment InteractivePlayerTranscriptionStatusFragment on Asset {\n  id\n  videoAssetsEnabledAt\n  transcriptionJob {\n    status\n    sieveJobErrorReason\n    id\n  }\n  transcription {\n    id\n  }\n}\n\nfragment TranscriptReaderFragment on AssetTranscription {\n  segments {\n    text\n    start\n    startTimecode\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca134a1211424a1caf099d67e97dde45";

export default node;
