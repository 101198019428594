import OrganizationZoomConnectionListItem, {
  OrganizationZoomConnectionListItemProps,
} from "@/admin/integrations/zoom/connection/OrganizationZoomConnectionListItem"
import useRefetchableZoomMeetingProviders from "@/zoom/util/hook/useRefetchableZoomMeetingProviders"
import { FC } from "react"

interface Props {
  itemProps?: Pick<
    OrganizationZoomConnectionListItemProps,
    "onClick" | "hideOverflow" | "hideStatus" | "selectedZoomProviderId"
  >
  showAddZoomAccountButton?: boolean
}

const OrganizationZoomConnectionList: FC<Props> = ({ itemProps }) => {
  const { zoomProviders } = useRefetchableZoomMeetingProviders()

  return (
    <ul data-testid={"OrganizationZoomConnectionList"}>
      {Boolean(zoomProviders.length) &&
        zoomProviders.map((meetingProvider) => (
          <OrganizationZoomConnectionListItem
            key={meetingProvider.id}
            meetingProviderKey={meetingProvider}
            {...itemProps}
          />
        ))}
    </ul>
  )
}

export default OrganizationZoomConnectionList
