import { DefaultLightThemeProvider } from "@/core/context/CustomThemeProvider"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const AutomationDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "automation-drawer-content" */ "@/admin/automation/drawer/AutomationDrawerContent"
    )
)

function AutomationDrawer() {
  const drawer = useGlobalDrawer("automation")
  const classes = useStyles()
  const modal = useInitUnsavedChangesModalContext()

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    // Automations drawer only exists in admin area, display light mode theme
    <DefaultLightThemeProvider>
      <UnsavedChangesModalProvider {...modal}>
        <DiscoDrawer
          containerClasses={{ drawerContainer: classes.drawer }}
          open={drawer.isOpen}
          onClose={handleCloseDrawer}
          testid={"AutomationDrawer.container"}
          shouldCloseOnEsc
        >
          {(drawer.params.automationId || drawer.params.automationTemplate) && (
            <AutomationDrawerContent />
          )}
        </DiscoDrawer>
      </UnsavedChangesModalProvider>
    </DefaultLightThemeProvider>
  )

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: drawer.close,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawer: {
    padding: 0,
    background: theme.palette.grey[100],
  },
}))

export default AutomationDrawer
