import CreateExperienceForm from "@/admin/experiences/create/CreateExperienceForm"
import ExperienceTemplateOverview, {
  EXPERIENCE_TEMPLATES,
} from "@/admin/experiences/create/ExperienceTemplateOverview"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import useCreateExperienceForm from "@/admin/experiences/create/useCreateExperienceForm"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoButton, DiscoModal, DiscoModalProps } from "@disco-ui"
import { observer } from "mobx-react-lite"

type Props = Pick<DiscoModalProps, "onClose" | "onBack"> & {
  template: ExperienceTemplateKind
  showPreview?: boolean
  navSectionId?: GlobalID
}

function CreateExperienceModal(props: Props) {
  const { template, showPreview = true, onBack, navSectionId, ...rest } = props
  const experienceLabel = useLabel("admin_experience")

  const form = useCreateExperienceForm(template, navSectionId)

  // Template overview
  const templateConfig = EXPERIENCE_TEMPLATES[template]
  if (!form.state.isTemplateConfirmed && templateConfig && showPreview) {
    return (
      <DiscoModal
        {...rest}
        isOpen
        onBack={onBack}
        title={templateConfig.title}
        modalContentLabel={templateConfig.title}
        width={"720px"}
        maxWidth={"90vw"}
        disableTransition
        body={
          <ExperienceTemplateOverview
            testid={"CreateExperienceModal.template-overview"}
            kind={template}
          />
        }
        buttons={[
          <DiscoButton
            key={"back"}
            color={"grey"}
            variant={"outlined"}
            onClick={onBack || rest.onClose}
            testid={"CreateExperienceButton.cancel.button"}
          >
            {onBack ? "Back" : "Cancel"}
          </DiscoButton>,
          <DiscoButton
            key={"use-template"}
            onClick={() => (form.state.isTemplateConfirmed = true)}
            testid={"CreateExperienceButton.use-template-button"}
          >
            {"Create"}
          </DiscoButton>,
        ]}
        testid={"CreateExperienceButton.modal"}
      />
    )
  }

  // Complete the form
  const typeLabel =
    template === "blank" || !templateConfig
      ? experienceLabel.singular
      : templateConfig.title
  return (
    <DiscoModal
      {...rest}
      isOpen
      onBack={onBack}
      title={`Create ${typeLabel}`}
      modalContentLabel={`Create ${typeLabel}`}
      width={"720px"}
      maxWidth={"90vw"}
      disableTransition
      body={<CreateExperienceForm form={form} onSuccess={props.onClose} />}
      buttons={[
        <DiscoButton
          key={"cancel"}
          color={"grey"}
          variant={"outlined"}
          onClick={props.onClose}
          testid={"CreateExperienceModal.cancel.button"}
        >
          {"Cancel"}
        </DiscoButton>,
        <DiscoButton
          key={"create-experience"}
          type={"submit"}
          form={"CreateExperienceForm"}
          testid={"CreateExperienceModal.save-as-draft.button"}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={form.isSubmitting}
        >
          {"Save as Draft"}
        </DiscoButton>,
      ]}
      testid={"CreateExperienceButton.modal"}
    />
  )
}

export default observer(CreateExperienceModal)
