import SlackUserImportPageHeader from "@/admin/integrations/slack/setup/SlackUserImportPageHeader"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoButton, DiscoLink } from "@disco-ui"

type Props = {
  isOnboarding?: boolean
}

function SlackUserImportSuccessStepContent({ isOnboarding }: Props) {
  return (
    <>
      <SlackUserImportPageHeader
        title={"Slack Setup Complete!"}
        subtitle={
          <>
            {"Check out "}
            <DiscoLink
              href={`${location.origin}${ROUTE_NAMES.ADMIN.MEMBERS.ONBOARDING.ROOT}`}
              target={"_blank"}
            >
              {"Member Onboarding"}
            </DiscoLink>
            {
              " to configure how new members will be onboarded into the Disco Slack App. The Slack onboarding step is on by default and can be disabled."
            }
          </>
        }
      />
      <DiscoButton
        to={
          isOnboarding ? ROUTE_NAMES.ADMIN.DASHBOARD : ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT
        }
      >
        {"Continue"}
      </DiscoButton>
    </>
  )
}

export default SlackUserImportSuccessStepContent
