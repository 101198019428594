import ThreeDotLoader from "@assets/images/onboarding/processing/three-dot-loader.svg"
import { DiscoText } from "@disco-ui"

function SlackUserInProgressStepContent() {
  return (
    <>
      <DiscoText variant={"heading-md"} marginBottom={3}>
        {"Proceeding with Slack Connection Setup..."}
      </DiscoText>
      <DiscoText marginBottom={3}>
        {"This screen will refresh when the process ends — Hang tight!"}
      </DiscoText>
      <ThreeDotLoader />
    </>
  )
}

export default SlackUserInProgressStepContent
