/**
 * @generated SignedSource<<a6bf324695550765682b678411118042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTrackMuxAssetStatus = "ready" | "preparing" | "errored" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetTrackStatusChipFragment$data = {
  readonly muxAssetTrackStatus: AssetTrackMuxAssetStatus | null;
  readonly isAutoGenerated: boolean;
  readonly " $fragmentType": "AssetTrackStatusChipFragment";
};
export type AssetTrackStatusChipFragment$key = {
  readonly " $data"?: AssetTrackStatusChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetTrackStatusChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetTrackStatusChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "muxAssetTrackStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutoGenerated",
      "storageKey": null
    }
  ],
  "type": "AssetTrack",
  "abstractKey": null
};

(node as any).hash = "2e62a2c6e921879fd0777c207be79066";

export default node;
