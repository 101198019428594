import { AutomationTemplateKind } from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import {
  AutomationTemplate,
  useAutomationDescriptions,
} from "@/admin/automation/utils/automationUtils"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoCardButton from "@disco-ui/button/DiscoCardButton"
import DiscoCard, { DiscoCardProps, DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { TestIDProps } from "@utils/typeUtils"
import AutomationTemplateCover from "./AutomationTemplateCover"

interface Props extends TestIDProps, Pick<DiscoCardProps, "classes" | "inCarousel"> {
  template: AutomationTemplate
  kind: AutomationTemplateKind
  onClose?(): void
}

function AutomationTemplateCard({
  template,
  kind,
  onClose,
  inCarousel,
  classes: customClasses,
}: Props) {
  const classes = useStyles()
  const drawer = useGlobalDrawer("automation")
  const { AUTOMATION_TEMPLATE_DESCRIPTIONS } = useAutomationDescriptions()

  if (kind === "custom") {
    return (
      <DiscoCardButton
        className={classes.card}
        onClick={openDrawer}
        data-testid={"AutomationTemplateCard.custom.button"}
      >
        <div className={classes.root}>
          <DiscoIcon className={classes.icon} icon={"add-circle"} />
          <DiscoText variant={"body-md-700"} color={"text.primary"}>
            {"Create Custom Automation"}
          </DiscoText>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Get started on a blank canvas to select your own triggers and actions."}
          </DiscoText>
        </div>
      </DiscoCardButton>
    )
  }

  return (
    <DiscoCard
      classes={customClasses}
      inCarousel={inCarousel}
      icon={<AutomationTemplateCover template={kind} />}
      title={template.automation.name}
      description={AUTOMATION_TEMPLATE_DESCRIPTIONS[kind]}
      onClick={openDrawer}
    />
  )

  function openDrawer() {
    drawer.open({ automationTemplate: kind })
    onClose?.()
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(3),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  icon: {
    height: "40px",
    width: "40px",
    gap: theme.spacing(1),
  },
  card: {
    height: "100%",
    backgroundColor: theme.palette.groovy.neutral[100],
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[200],
    },
  },
}))

export function AutomationTemplateCardSkeleton(props: Pick<Props, "classes">) {
  const { classes } = props
  return <DiscoCardSkeleton testid={"AutomationTemplateCardSkeleton"} classes={classes} />
}

export default Relay.withSkeleton({
  component: AutomationTemplateCard,
  skeleton: AutomationTemplateCardSkeleton,
})
