import AdminContentLibraryItemUsages from "@/admin/content-library/AdminContentLibraryItemUsages"
import { ContentInstancesTooltipFragment$key } from "@/admin/content-library/__generated__/ContentInstancesTooltipFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTooltip, DiscoTooltipProps } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentInstancesTooltipFragment$key
  children: DiscoTooltipProps["children"]
}

function ContentInstancesTooltip({ contentKey, children }: Props) {
  const classes = useStyles()

  const content = useFragment<ContentInstancesTooltipFragment$key>(
    graphql`
      fragment ContentInstancesTooltipFragment on Content {
        usages {
          totalCount
        }
        ...AdminContentLibraryItemUsages_ContentFragment
      }
    `,
    contentKey
  )

  return (
    <DiscoTooltip
      content={renderContent()}
      interactive
      placement={"top"}
      classes={{ tooltip: classes.tooltip }}
    >
      {children}
    </DiscoTooltip>
  )

  function renderContent() {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <DiscoText variant={"body-xs-uppercase"}>{"Instances"}</DiscoText>
          <DiscoText variant={"body-sm-600"} className={classes.count}>
            {content.usages.totalCount}
          </DiscoText>
        </div>
        <AdminContentLibraryItemUsages
          variant={"tooltip"}
          contentKey={content}
          // Pass in total count to allow skeleton to properly size so that it does not
          // cause the tooltip to move when resizing
          totalCount={content.usages.totalCount}
        />
      </div>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    width: "328px",
    maxWidth: "328px",
    padding: 0,
  },
  container: {
    display: "grid",
    borderBottomRightRadius: theme.measure.borderRadius.big,
    overflow: "hidden",
  },
  header: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    padding: theme.spacing(2, 2, 1),
  },
  count: {
    width: "28px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.default,
    backgroundColor: theme.palette.groovy.neutral[500],
    color: theme.palette.groovy.neutral[100],
  },
}))

export default ContentInstancesTooltip
