/**
 * @generated SignedSource<<b410b701555da9c9474440ff70c762c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetTracksListQuery$variables = {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  assetId: string;
  assetTrackIds?: ReadonlyArray<string> | null;
};
export type AssetTracksListQuery$data = {
  readonly node: {
    readonly __typename: "Asset";
    readonly " $fragmentSpreads": FragmentRefs<"AssetTracksList_PaginationFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AssetTracksListQuery = {
  variables: AssetTracksListQuery$variables;
  response: AssetTracksListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetTrackIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "assetId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "assetTrackIds",
    "variableName": "assetTrackIds"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetTracksListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v8/*: any*/),
                "kind": "FragmentSpread",
                "name": "AssetTracksList_PaginationFragment"
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetTracksListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasSubtitles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTrack",
                "kind": "LinkedField",
                "name": "autoGeneratedSubtitle",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscriptionJob",
                "kind": "LinkedField",
                "name": "transcriptionJob",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sieveJobErrorReason",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "AssetTrackNodeConnection",
                "kind": "LinkedField",
                "name": "subtitleTracks",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTrackNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetTrack",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isAutoGenerated",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "muxAssetTrackStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Asset",
                            "kind": "LinkedField",
                            "name": "trackAsset",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "videoAssetId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Asset",
                            "kind": "LinkedField",
                            "name": "videoAsset",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "notAutoGeneratedSubtitleTracks",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "isAutoGenerated",
                                    "value": false
                                  }
                                ],
                                "concreteType": "AssetTrackNodeConnection",
                                "kind": "LinkedField",
                                "name": "subtitleTracks",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssetTrackNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AssetTrack",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v9/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "subtitleTracks(isAutoGenerated:false)"
                              },
                              (v9/*: any*/),
                              {
                                "alias": "deleteSubtitleTracks",
                                "args": null,
                                "concreteType": "AssetTrackNodeConnection",
                                "kind": "LinkedField",
                                "name": "subtitleTracks",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "assetTrackIds"
                ],
                "handle": "connection",
                "key": "AssetTracksList__subtitleTracks",
                "kind": "LinkedHandle",
                "name": "subtitleTracks"
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b15902cbacdb4e4ba78858c436caf36f",
    "id": null,
    "metadata": {},
    "name": "AssetTracksListQuery",
    "operationKind": "query",
    "text": "query AssetTracksListQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $assetId: ID!\n  $assetTrackIds: [ID!]\n) {\n  node(id: $assetId) {\n    __typename\n    ... on Asset {\n      ...AssetTracksList_PaginationFragment_swHt6\n    }\n    id\n  }\n}\n\nfragment AssetTrackFormLanguageSectionFragment on Asset {\n  notAutoGeneratedSubtitleTracks: subtitleTracks(isAutoGenerated: false) {\n    edges {\n      node {\n        id\n        languageCode\n      }\n    }\n  }\n}\n\nfragment AssetTrackStatusChipFragment on AssetTrack {\n  muxAssetTrackStatus\n  isAutoGenerated\n}\n\nfragment AssetTracksListItemFragment on AssetTrack {\n  id\n  url\n  name\n  languageCode\n  isAutoGenerated\n  muxAssetTrackStatus\n  trackAsset {\n    name\n    id\n  }\n  ...AssetTrackStatusChipFragment\n  ...UpdateAssetTrackFormFragment\n  ...DeleteAssetTrackButtonFragment\n}\n\nfragment AssetTracksList_PaginationFragment_swHt6 on Asset {\n  id\n  hasSubtitles\n  autoGeneratedSubtitle {\n    id\n  }\n  transcriptionJob {\n    sieveJobErrorReason\n    id\n  }\n  subtitleTracks(first: $first, after: $after, last: $last, before: $before, assetTrackIds: $assetTrackIds) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...AssetTracksListItemFragment\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n\nfragment DeleteAssetTrackButtonFragment on AssetTrack {\n  id\n  videoAsset {\n    id\n    deleteSubtitleTracks: subtitleTracks {\n      totalCount\n    }\n  }\n}\n\nfragment UpdateAssetTrackFormFragment on AssetTrack {\n  id\n  languageCode\n  videoAssetId\n  videoAsset {\n    ...AssetTrackFormLanguageSectionFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0806c2b2e99b0f5115cfcb8e1d713850";

export default node;
