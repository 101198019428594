import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import { DeleteAssetTrackButtonFragment$key } from "@/admin/asset/tracks/__generated__/DeleteAssetTrackButtonFragment.graphql"
import { DeleteAssetTrackButtonMutation } from "@/admin/asset/tracks/__generated__/DeleteAssetTrackButtonMutation.graphql"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  assetTrackKey: DeleteAssetTrackButtonFragment$key
  children: OverridableDiscoButtonChildren
}

const DeleteAssetTrackButton = observer<Props>((props) => {
  const { assetTrackKey, children } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { closeModal: closeAssetSubtitlesModal } = useAssetSubtitlesModal()

  const assetTrack = useFragment<DeleteAssetTrackButtonFragment$key>(
    graphql`
      fragment DeleteAssetTrackButtonFragment on AssetTrack {
        id @deleteRecord
        videoAsset {
          id
          deleteSubtitleTracks: subtitleTracks {
            totalCount
          }
        }
      }
    `,
    assetTrackKey
  )

  const [commit, isDeleting] = useMutation<DeleteAssetTrackButtonMutation>(graphql`
    mutation DeleteAssetTrackButtonMutation($id: ID!) {
      deleteAssetTrack(id: $id) {
        deletedAssetTrackId @deleteRecord
      }
    }
  `)

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>
      <DiscoWarningModal
        testid={"DeleteAssetTrackButton"}
        isOpen={isModalOpen}
        confirmationButtonProps={{
          onClick: handleDelete,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete it",
        }}
        onClose={closeModal}
        modalContentLabel={"Delete Media Dialog"}
        title={`Delete this subtitle?`}
        description={
          "Are you sure you want to permanently delete this subtitle? This action cannot be undone."
        }
      />
    </>
  )

  function handleDelete() {
    commit({
      variables: {
        id: assetTrack.id,
      },
      onCompleted() {
        displaySuccessToast({
          message: `Subtitle deleted`,
          testid: `DeleteAssetTrackButton.success-toast`,
        })

        closeAssetSubtitlesModal()
      },
      onError(error) {
        displayErrorToast(error)
      },
      updater(store) {
        const assetRecord = store.get(assetTrack.videoAsset.id)
        if (!assetRecord) return

        assetRecord.invalidateRecord()
      },
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
})

export default DeleteAssetTrackButton
