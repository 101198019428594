import ContentUtils from "@/content/util/contentUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown, {
  DiscoTableFilterDropdownSkeleton,
} from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"

type AdminContentListLabelOption = SelectOption<GlobalID | null>

export type AdminContentListLabelFilterState = {
  contentLabelId: AdminContentListLabelOption | null
}

interface AdminContentListLabelFilterProps extends TestIDProps {
  filterState: AdminContentListLabelFilterState
  setFilterState: (state: AdminContentListLabelFilterState) => void
}

function AdminContentListLabelFilter(props: AdminContentListLabelFilterProps) {
  const { filterState, setFilterState } = props

  const contentLabels = ContentUtils.useContentLabels()

  const options: AdminContentListLabelOption[] = [
    { value: null, title: "All Labels" },
    ...ArrayUtils.spreadIf(
      contentLabels.map((contentLabel) => ({
        value: contentLabel.id,
        title: contentLabel.label,
      })),
      true
    ),
  ]

  return (
    <DiscoTableFilterDropdown
      testid={"AdminContentList.label-select"}
      isNested
      options={options}
      onOptionSelect={(contentLabelId) => setFilterState({ contentLabelId })}
      selectedOption={filterState.contentLabelId}
      dropdown={{ label: "Label", icon: null }}
    />
  )
}

export default Relay.withSkeleton({
  component: AdminContentListLabelFilter,
  skeleton: DiscoTableFilterDropdownSkeleton,
})
