import { AdminContentLibraryItemUsagesPaginationQuery } from "@/admin/content-library/__generated__/AdminContentLibraryItemUsagesPaginationQuery.graphql"
import { AdminContentLibraryItemUsagesQuery } from "@/admin/content-library/__generated__/AdminContentLibraryItemUsagesQuery.graphql"
import { AdminContentLibraryItemUsages_ContentFragment$key } from "@/admin/content-library/__generated__/AdminContentLibraryItemUsages_ContentFragment.graphql"
import { AdminContentLibraryItemUsages_PaginationFragment$key } from "@/admin/content-library/__generated__/AdminContentLibraryItemUsages_PaginationFragment.graphql"
import ContentUsageInstanceItem, {
  ContentUsageInstanceItemSkeleton,
} from "@/content-usage/ContentUsageInstanceItem"
import { ContentUsageDrawerSubtab } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const USAGES_PER_PAGE = 12

interface AdminContentLibraryItemUsagesProps extends TestIDProps {
  // Pass in total count to allow skeleton to properly size so that it does not
  // cause the tooltip to move when resizing
  totalCount?: number
  contentKey?: AdminContentLibraryItemUsages_ContentFragment$key | null
  variant: "tooltip" | "list"
  ignoredUsageId?: string
  drawerTabOnItemNavigate?: ContentUsageDrawerSubtab
  extraActions?: React.ReactNode
}

function AdminContentLibraryItemUsages({
  contentKey,
  variant,
  drawerTabOnItemNavigate = "details",
  extraActions,
  ignoredUsageId,
}: AdminContentLibraryItemUsagesProps) {
  const content = useFragment<AdminContentLibraryItemUsages_ContentFragment$key>(
    graphql`
      fragment AdminContentLibraryItemUsages_ContentFragment on Content {
        id
      }
    `,
    contentKey || null
  )

  const response = useLazyLoadQuery<AdminContentLibraryItemUsagesQuery>(
    graphql`
      query AdminContentLibraryItemUsagesQuery($id: ID!, $first: Int!, $after: String) {
        content: node(id: $id) {
          ... on Content {
            id
            ...AdminContentLibraryItemUsages_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      id: content?.id || "",
      first: USAGES_PER_PAGE,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AdminContentLibraryItemUsagesPaginationQuery,
    AdminContentLibraryItemUsages_PaginationFragment$key
  >(
    graphql`
      fragment AdminContentLibraryItemUsages_PaginationFragment on Content
      @refetchable(queryName: "AdminContentLibraryItemUsagesPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        usages(first: $first, after: $after)
          @connection(key: "AdminContentLibraryItemUsages__usages") {
          edges {
            node {
              id
              ...ContentUsageInstanceItem_ContentUsageFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    response.content
  )

  const usages = Relay.connectionToArray(data?.usages)
  const classes = useStyles({ variant })

  if (usages.length === 0)
    return <AdminContentLibraryItemUsagesEmptyState variant={variant} />

  return (
    <div className={classes.usagesList}>
      {usages.map((usage) => {
        if (usage.id === ignoredUsageId) return null

        return (
          <ContentUsageInstanceItem
            key={usage.id}
            variant={variant}
            contentUsageKey={usage}
            navigateOnClick
            drawerTabOnItemNavigate={drawerTabOnItemNavigate}
          />
        )
      })}
      {hasNext && (
        <DiscoScrolledIntoView
          isLoading={isLoadingNext}
          onScrolledIntoView={() => loadNext(USAGES_PER_PAGE)}
          skeleton={<ContentUsageInstanceItemSkeleton variant={variant} />}
        />
      )}
      {extraActions}
    </div>
  )
}

function AdminContentLibraryItemUsagesEmptyState(
  props: Pick<AdminContentLibraryItemUsagesProps, "variant">
) {
  const classes = useStyles({ variant: props.variant })

  return (
    <div className={classes.usagesList}>
      <DiscoText
        variant={"body-sm"}
        color={"text.secondary"}
        className={classes.unusedText}
      >
        {"Content has not been used yet"}
      </DiscoText>
    </div>
  )
}

function AdminContentLibraryItemUsagesSkeleton({
  totalCount = 4,
  variant,
}: AdminContentLibraryItemUsagesProps) {
  const classes = useStyles({ variant })

  if (totalCount === 0) {
    return <AdminContentLibraryItemUsagesEmptyState variant={variant} />
  }

  const numOfUsageSkeletons = Math.min(totalCount, 4)
  const skeletons = Array.from({ length: numOfUsageSkeletons })

  return (
    <div className={classes.usagesList}>
      {skeletons.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ContentUsageInstanceItemSkeleton key={index} variant={variant} />
      ))}
    </div>
  )
}

type StyleProps = {
  variant: AdminContentLibraryItemUsagesProps["variant"]
}

const useStyles = makeUseStyles((theme) => ({
  usagesList: ({ variant }: StyleProps) => ({
    display: "grid",
    gap: theme.spacing(0.5),
    maxHeight: "250px",
    overflowY: "auto",

    ...(variant === "tooltip" && {
      maxHeight: "190px",
      padding: theme.spacing(0, 1, 1),
    }),
  }),
  unusedText: {
    padding: theme.spacing(0, 1, 1.5),
  },
}))

export default Relay.withSkeleton({
  component: AdminContentLibraryItemUsages,
  skeleton: AdminContentLibraryItemUsagesSkeleton,
})
