/**
 * @generated SignedSource<<47f6699de3c02757a95feabc62e2b74b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAssetTrackFormQuery$variables = {
  assetId: string;
};
export type CreateAssetTrackFormQuery$data = {
  readonly node: {
    readonly __typename: "Asset";
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AssetTrackFormLanguageSectionFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CreateAssetTrackFormQuery = {
  variables: CreateAssetTrackFormQuery$variables;
  response: CreateAssetTrackFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "assetId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAssetTrackFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssetTrackFormLanguageSectionFragment"
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAssetTrackFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "notAutoGeneratedSubtitleTracks",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isAutoGenerated",
                    "value": false
                  }
                ],
                "concreteType": "AssetTrackNodeConnection",
                "kind": "LinkedField",
                "name": "subtitleTracks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTrackNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetTrack",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "languageCode",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "subtitleTracks(isAutoGenerated:false)"
              }
            ],
            "type": "Asset",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0969ef5eb3002c3ef783513c8367dc0c",
    "id": null,
    "metadata": {},
    "name": "CreateAssetTrackFormQuery",
    "operationKind": "query",
    "text": "query CreateAssetTrackFormQuery(\n  $assetId: ID!\n) {\n  node(id: $assetId) {\n    __typename\n    ... on Asset {\n      __typename\n      id\n      ...AssetTrackFormLanguageSectionFragment\n    }\n    id\n  }\n}\n\nfragment AssetTrackFormLanguageSectionFragment on Asset {\n  notAutoGeneratedSubtitleTracks: subtitleTracks(isAutoGenerated: false) {\n    edges {\n      node {\n        id\n        languageCode\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fc8452bf3be4763805ff843d0d9fd43";

export default node;
