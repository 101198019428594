/**
 * @generated SignedSource<<8487484aa16b8bf1272f55df4b837f0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MemberGroupVisibility = "everyone" | "admin_only" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupVisibilityChipFragment$data = {
  readonly visibility: MemberGroupVisibility;
  readonly " $fragmentType": "MemberGroupVisibilityChipFragment";
};
export type MemberGroupVisibilityChipFragment$key = {
  readonly " $data"?: MemberGroupVisibilityChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupVisibilityChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberGroupVisibilityChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "e185b51fe9d23a212227fb6f15598b41";

export default node;
