/**
 * @generated SignedSource<<ea2e5c476ea4936ee02d07c8ab3506d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BotStatus = "active" | "paused" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCopilotBotFragment$data = {
  readonly copilotBot: {
    readonly id: string;
    readonly name: string;
    readonly status: BotStatus;
  } | null;
  readonly " $fragmentType": "useCopilotBotFragment";
};
export type useCopilotBotFragment$key = {
  readonly " $data"?: useCopilotBotFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCopilotBotFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCopilotBotFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Bot",
      "kind": "LinkedField",
      "name": "copilotBot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ac8209c630d378b2a8388a6601be4afa";

export default node;
