/**
 * @generated SignedSource<<ddd5e84a329b41aa88aab582ad3e9d26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type automationUtils_useGetDefaultChatChannelSendAsFragment$data = {
  readonly viewerMembership: {
    readonly id: string;
    readonly streamChatUserId: string | null;
  } | null;
  readonly " $fragmentType": "automationUtils_useGetDefaultChatChannelSendAsFragment";
};
export type automationUtils_useGetDefaultChatChannelSendAsFragment$key = {
  readonly " $data"?: automationUtils_useGetDefaultChatChannelSendAsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"automationUtils_useGetDefaultChatChannelSendAsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "automationUtils_useGetDefaultChatChannelSendAsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamChatUserId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2aaed144ed261cdddac6095f2972b6d8";

export default node;
