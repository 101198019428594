import {
  BadgeFragment$data,
  BadgeFragment$key,
} from "@/admin/experiences/badges/__generated__/BadgeFragment.graphql"
import {
  AppIcons,
  AppSpecialIconKind,
} from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ColorUtils from "@assets/style/util/ColorUtils"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIconPickerIcons } from "@disco-ui/icon-picker/DiscoIconPickerIcons"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

export type BadgeSize = 24 | 40 | 48 | 64
interface BadgeProps {
  badgeKey: BadgeFragment$key | null
  badgeData?: Partial<BadgeFragment$data>
  className?: string
  size?: BadgeSize
  primarySvgColor?: string
  fallbackColor?: string
  isSelected?: boolean
  isSideBar?: boolean
}

function Badge({
  badgeKey,
  badgeData,
  className,
  size = 24,
  primarySvgColor,
  fallbackColor,
  isSelected,
  isSideBar = false,
}: BadgeProps) {
  let badge = useFragment<BadgeFragment$key>(
    graphql`
      fragment BadgeFragment on Badge {
        kind
        color
        icon
        emoji
        mediaUrl
      }
    `,
    badgeKey
  )
  if (badgeData) {
    badge = badgeData as BadgeFragment$data
  }

  const theme = useTheme()
  const badgeColor = getBadgeColor()
  const svgColor = getSvgColor()
  const icon = badge?.icon

  const classes = useStyles({
    size,
    badgeColor,
    svgColor,
    icon,
  })

  if (!badge) return null

  return (
    <div className={classNames(classes.badgeContainer, className)}>{renderBadge()}</div>
  )

  function renderBadge() {
    switch (badge?.kind) {
      case "upload":
        return badge.mediaUrl ? (
          <img src={badge!.mediaUrl!} alt={""} className={classes.badgeContainer} />
        ) : (
          <></>
        )
      case "emoji":
        return badge.emoji
      case "icon":
        if (!badge.icon) return null
        if (!DiscoIconPickerIcons[badge.icon as DiscoIconPickerIcons])
          return AppIcons[badge.icon as AppSpecialIconKind]
        return isSelected && badgeColor && ColorUtils.isTransparent(badgeColor)
          ? DiscoIconPickerIcons[badge.icon as DiscoIconPickerIcons].active
          : DiscoIconPickerIcons[badge.icon as DiscoIconPickerIcons].default
      default:
        return badge?.color ? <div> </div> : null
    }
  }

  function getSvgColor() {
    if (primarySvgColor) return ColorUtils.makeSafeHex(primarySvgColor)
    if (isSelected && badgeColor && ColorUtils.isTransparent(badgeColor)) {
      return theme.palette.primary.contrastText // makes selected sidebar item white
    }
    return badgeColor
      ? ColorUtils.getContrastColor(theme, badgeColor, isSideBar)
      : undefined
  }

  function getBadgeColor() {
    if (badge?.kind === "upload") return undefined
    const color = ColorUtils.makeSafeHex(badge?.color)
    // Invert white badges in dark mode
    if (/^#f{6}(f{2})?$/i.test(color) && theme.palette.type === "dark") {
      return fallbackColor || theme.palette.background.paper
    }
    return color
  }
}

type StyleProps = {
  svgColor?: string | undefined
  badgeColor?: string
  size?: BadgeSize
  icon?: string | null | undefined
}

const useStyles = makeUseStyles((theme) => ({
  badgeContainer: ({ size, svgColor, badgeColor, icon }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.default,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    backgroundColor: badgeColor,
    "& svg": {
      ...(icon !== "house" && {
        color: `${svgColor} !important`,
      }),
    },
    ...styleIf(size === 64, {
      height: "64px",
      width: "64px",
      fontSize: "36px",
      "& svg": {
        height: "64px",
        width: "64px",
        color: `${svgColor} !important`,
      },
    }),
    ...styleIf(size === 48, {
      height: "48px",
      width: "48px",
      fontSize: "24px",
      "& svg": {
        height: "42px",
        width: "42px",
        color: `${svgColor} !important`,
      },
    }),
    ...styleIf(size === 40, {
      height: "40px",
      width: "40px",
      fontSize: "20px",
      "& svg": {
        height: "24px",
        width: "24px",
        color: `${svgColor} !important`,
      },
    }),
    ...styleIf(size === 24, {
      height: "24px",
      width: "24px",
      fontSize: "16px",
      "& > svg": {
        height: "24px",
        width: "24px",
        ...(icon !== "house" && {
          color: `${svgColor} !important`,
        }),
      },
    }),
  }),
}))

export default Badge
