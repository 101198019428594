/**
 * @generated SignedSource<<6f0a916cdb6d4156483b8f9839a833eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssetTranscriptionJobSieveJobErrorReason = "no_audio" | "generic" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetTracksList_PaginationFragment$data = {
  readonly id: string;
  readonly hasSubtitles: boolean;
  readonly autoGeneratedSubtitle: {
    readonly id: string;
  } | null;
  readonly transcriptionJob: {
    readonly sieveJobErrorReason: AssetTranscriptionJobSieveJobErrorReason | null;
  } | null;
  readonly subtitleTracks: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AssetTracksListItemFragment">;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly startCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "AssetTracksList_PaginationFragment";
};
export type AssetTracksList_PaginationFragment$key = {
  readonly " $data"?: AssetTracksList_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetTracksList_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "subtitleTracks"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "assetTrackIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./AssetTracksListPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "AssetTracksList_PaginationFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSubtitles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetTrack",
      "kind": "LinkedField",
      "name": "autoGeneratedSubtitle",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetTranscriptionJob",
      "kind": "LinkedField",
      "name": "transcriptionJob",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sieveJobErrorReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "subtitleTracks",
      "args": [
        {
          "kind": "Variable",
          "name": "assetTrackIds",
          "variableName": "assetTrackIds"
        }
      ],
      "concreteType": "AssetTrackNodeConnection",
      "kind": "LinkedField",
      "name": "__AssetTracksList__subtitleTracks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetTrackNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetTrack",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AssetTracksListItemFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": null
};
})();

(node as any).hash = "104332674902c80567c3bb9536906237";

export default node;
