import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { graphql, useFragment } from "react-relay"
import { AdminContentListCollectionAppFilterFragment$key } from "./__generated__/AdminContentListCollectionAppFilterFragment.graphql"

type AdminContentListCollectionOption = SelectOption<GlobalID | null>

export type AdminContentListCollectionFilterState = {
  selectedCollection: AdminContentListCollectionOption | null
}

interface AdminContentListCollectionAppFilterProps {
  filterState: AdminContentListCollectionFilterState
  setFilterState: (state: AdminContentListCollectionFilterState) => void
  organizationKey: AdminContentListCollectionAppFilterFragment$key
}

export default function AdminContentListCollectionAppFilter({
  organizationKey,
  filterState,
  setFilterState,
}: AdminContentListCollectionAppFilterProps) {
  const organization = useFragment<AdminContentListCollectionAppFilterFragment$key>(
    graphql`
      fragment AdminContentListCollectionAppFilterFragment on Organization {
        id
        apps(kind: collection, includeNested: true) {
          edges {
            node {
              id
              customAppTitle
              collection {
                id
              }
            }
          }
        }
      }
    `,
    organizationKey
  )

  const collections = Relay.connectionToArray(organization.apps)

  const options: AdminContentListCollectionOption[] = [
    { value: null, title: "Any Collection" },
    ...collections.map((c) => ({
      value: c.collection?.id || "",
      title: c.customAppTitle || "",
    })),
  ]

  const selectedOption = filterState.selectedCollection

  return (
    <DiscoTableFilterDropdown
      testid={"AdminContentList.collection-select"}
      isNested
      options={options}
      onOptionSelect={handleSelect}
      selectedOption={selectedOption}
      dropdown={{ label: "Collection", icon: null }}
    />
  )

  function handleSelect(option: AdminContentListCollectionOption | null) {
    if (option?.value)
      setFilterState({
        ...filterState,
        selectedCollection: option,
      })
    else
      setFilterState({
        ...filterState,
        selectedCollection: null,
      })
  }
}
