import { AdminContentListToolbarState } from "@/admin/content-library/filters/AdminContentListToolbar"
import DiscoTableFilterTags from "@disco-ui/table/header/filter/DiscoTableFilterTags"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"

type Props = {
  filterState: AdminContentListToolbarState
  setFilterState: (state: AdminContentListToolbarState) => void
}

function AdminContentListFilterTags({ filterState, setFilterState }: Props) {
  const theme = useTheme()

  const showFilterTags =
    Boolean(filterState.contentLabelId) ||
    Boolean(filterState.selectedProduct) ||
    Boolean(filterState.selectedCollection)

  return (
    <DiscoTableFilterTags isVisible={showFilterTags} marginBottom={2}>
      {filterState.contentLabelId && (
        <DiscoTag
          testid={"AdminContentListFilterTag.content-label"}
          name={filterState.contentLabelId.title}
          backgroundColor={theme.palette.background.paper}
          onDelete={() => {
            setFilterState({
              ...filterState,
              contentLabelId: null,
            })
          }}
        />
      )}
      {filterState.selectedProduct && (
        <DiscoTag
          testid={"AdminContentListFilterTag.product"}
          name={filterState.selectedProduct.title}
          backgroundColor={theme.palette.background.paper}
          onDelete={() => {
            setFilterState({
              ...filterState,
              selectedProduct: null,
            })
          }}
        />
      )}
      {filterState.selectedCollection && (
        <DiscoTag
          testid={"AdminContentListFilterTag.collection"}
          name={filterState.selectedCollection.title}
          backgroundColor={theme.palette.background.paper}
          onDelete={() => {
            setFilterState({
              ...filterState,
              selectedCollection: null,
            })
          }}
        />
      )}
    </DiscoTableFilterTags>
  )
}

export default AdminContentListFilterTags
