import { StartAssetTranscriptionJobButtonMutation } from "@/admin/asset/transcription/__generated__/StartAssetTranscriptionJobButtonMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { rest } from "lodash"
import { observer } from "mobx-react-lite"
import { graphql } from "react-relay"

interface Props extends TestIDProps {
  assetId: GlobalID
  children: OverridableDiscoButtonChildren
  onStart?: () => void
}

function StartAssetTranscriptionJobButton({
  assetId,
  children,
  onStart,
  testid = "StartAssetTranscriptionJobButton",
}: Props) {
  const form = useFormStore<StartAssetTranscriptionJobButtonMutation>(
    graphql`
      mutation StartAssetTranscriptionJobButtonMutation($input: UpdateAssetInput!) {
        response: UpdateAsset(input: $input) {
          node {
            id
            ...InteractivePlayerTranscriptionStatusFragment
            ...InteractivePlayerSubtitlesTabContentFragment
            transcriptionJob {
              status
            }
            transcription {
              ...InteractivePlayerOverviewTabContentFragment
              ...InteractivePlayerTranscriptTabContentFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: assetId,
    }
  )
  return (
    <OverridableDiscoButton
      {...rest}
      onClick={handleClick}
      shouldDisplaySpinner={form.isSubmitting}
    >
      {children}
    </OverridableDiscoButton>
  )
  async function handleClick() {
    const { didSave, response } = await form.submit({
      ...form.state,
      enableVideoAssets: true,
    })
    if (!didSave || !response?.node?.id) return

    const { transcriptionJob } = response.node
    displaySuccessToast({
      message:
        // If the assets are generated, don't need to show the processing message
        transcriptionJob?.status === "completed"
          ? "Generating subtitle, please check back later."
          : `Generating asset... This process may take a few minutes, but you can check back later.`,
      testid: `${testid}.success-toast`,
    })

    onStart?.()
  }
}
export default observer(StartAssetTranscriptionJobButton)
