import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import classnames from "classnames"

interface Props {
  className?: string
  showBorder?: boolean
  size?: number
}

function ChatBotAvatar({ className, showBorder = false, size = 40 }: Props) {
  const classes = useStyles({ showBorder, size })
  return (
    <div className={classnames(classes.container, className)}>
      <AIStarsIcon width={20} height={20} />
    </div>
  )
}

type StyleProps = {
  showBorder: boolean
  size: number
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ showBorder, size }: StyleProps) => ({
    height: `${size}px`,
    width: `${size}px`,
    borderRadius: theme.measure.borderRadius.medium,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight,

    ...styleIf(showBorder, {
      background: `${
        theme.palette.type === "dark"
          ? theme.palette.aiGradient.bluePurpleDark
          : theme.palette.aiGradient.aiLight
      } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
      border: "1px solid transparent",
    }),
  }),
}))

export default ChatBotAvatar
