import { AutomationTemplateKind } from "@/admin/automation/drawer/__generated__/AutomationDrawerContentMutation.graphql"
import AutomationTemplateCard, {
  AutomationTemplateCardSkeleton,
} from "@/admin/automation/templates/card/AutomationTemplateCard"
import { useAutomationTemplates } from "@/admin/automation/utils/automationUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import { observer } from "mobx-react-lite"

export interface Props {
  onClose: () => void
}

function AutomationTemplatesGrid({ onClose }: Props) {
  const classes = useStyles()
  const templates = useAutomationTemplates()

  if (!templates) {
    return (
      <DiscoEmptyState
        subtitle={"There are no automation templates"}
        testid={"AutomationTemplatesGrid"}
      />
    )
  }

  return (
    <div className={classes.root}>
      {Object.entries(templates).map(([kind, template]) => {
        return (
          <AutomationTemplateCard
            key={kind}
            testid={`AutomationTemplatesGrid.${kind}`}
            template={template}
            kind={kind as AutomationTemplateKind}
            onClose={onClose}
            classes={{ card: classes.card }}
          />
        )
      })}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    gap: theme.spacing(2.5),
  },
  card: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

function AutomationTemplatesGridSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AutomationTemplateCardSkeleton />
      <AutomationTemplateCardSkeleton />
      <AutomationTemplateCardSkeleton />
      <AutomationTemplateCardSkeleton />
      <AutomationTemplateCardSkeleton />
      <AutomationTemplateCardSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(AutomationTemplatesGrid),
  skeleton: AutomationTemplatesGridSkeleton,
})
